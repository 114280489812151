import React from "react";

function chord(named) {
    var out = {
      named : named || ' ',
      allnotes () {
        return ['c','c#','d','d#','e','f','f#','g','g#','a','a#','b'];
      },
      pianoNotes() {
        // 0-8 all the notes on the piano with the number
        // c4 is middle c, which is below the staff on the treble cleff
        // on guitar it is fifth fret, third string
        var out = ['a0','a#0','b0'];
        for(var i =1;i<8;i++) {
          out = out.concat(this.allnotes().map(n => n+i ));
        }
        out.push('c8');
        return out;
      },
      sharpOrder() {
        return 'FCGDAEB'.toLocaleLowerCase().split('');
      },
      flatOrder() {
        this.sharpOrder().reverse();
      },
      sharpsFlatsKey(songKey) {
        
        var m = [
          ['C Am',[]],
          ['G Em',['f#']],
          ['D Bm',['f#','c#']],
          ['A F#m',['f#','c#','g#']],
          ['E C#m',['f#','c#','g#','d#']],
          ['B G#m',['f#','c#','g#','d#','a#']],
          ['F #D#m',['f#','c#','g#','d#','a#','e#']],
          ['C# A#m',['f#','c#','g#','d#','a#','e#','b#']],
          ['F Dm',['b♭']],
          ['B♭ Gm',['b♭','E♭']],
          ['E♭ Cm',['b♭','E♭','A♭']],
          ['A♭ Fm',['b♭','E♭','A♭','D♭']],
          ['D♭ B♭m',['b♭','E♭','A♭','D♭','G♭']],
          ['G♭ E♭m',['b♭','E♭','A♭','D♭','G♭','C♭']],
          ['C♭ A♭m',['b♭','E♭','A♭','D♭','G♭','C♭','F♭']]
        ];
        
        var out =  m.filter(v => {
          // crazy have to convert to string here but perhaps bc multi array above is mixed type
          let test = v[0].toString().split(/\s/);
          //console.log('sharpflatkey test',test,test.indexOf(songKey.toString()),songKey);
          return test.indexOf(songKey.toString()) > -1
        });
        //console.log('sharpflatkey',songKey,out);
        return  out?.[0]?.[1];
      },
      staffPos() {
        return [0,0,1,1,2,3,3,4,4,5,5,6];
      },
      staffIndex(note) {
        return this.staffPos()[this.noteIndex(note)];
      },
      noteAtIndex(idx) {

        if (idx < 0) {
          idx = this.allnotes().length + idx;
          // if too high need mod here from abs
        } else if (idx > this.allnotes().length - 1) {
          idx = idx % this.allnotes().length;
        }
        return this.allnotes()[idx];
      },
      intervals () {
        //https://en.wikipedia.org/wiki/Interval_(music)
        // perfect 15th is double octave
        return ['perfect unison','minor 2nd','major 2nd','minor third','major 3rd','perfect 4th','tritone','perfect 5th','minor 6th','major 6th','minor 7th','major 7th'
        ,'perfect octave','minor 9th','major 9th','minor 10th','major 10th','perfect 11th','','perfect 12th','minor 13th','major 13th','minor 14th','major 14th','perfect 15th',''];
      },
      valid() {
        // matches general format for chord meausre , triangle is M, - is m, + is augmented, circle diminished, half circle is half diminished
        return named.replace(/[\(\)\[\]]/g,'').match(/^[ABCDEFG][#b]?[diomagjunbsM1234567890*\-Δøo+]*(\/[ABCDEFG][#b]?)?$/) ? true : false;
      },
      intrvl () {
        // P = Perfect, m = minor, M = major, d = diminished, D = dominant, TT = tritone, A = augmented
        return ['P1','m2','M2','m3','M3','P4','d5','P5','m6','M6','m7','M7','P8','m9','M9','m10','M10','P11','d12','P12','m13','M13','m14','M14','P15','A15'];
        // this following site give all extended chord options across major progressions
        // more on scales https://scalenumbers.com/ 
      },
      intrvlToLong(intr) {
        let idx = this.intrvl().indexOf(intr);
        if (idx === -1 ) {
          return idx.toString();
        }
        return this.intervals()[idx];
      },
      modes() {
        //https://www.guitarlessonworld.com/lessons/modes/
        var out = [['M','M7','ionian','lydian'],['m','m7','dorian','phrygian','aeolian'],['M','D7','mixolydian'],['d','m7b5','locrian']];
        return out;
      },
      numeral() {
        return ['I','ii','iii','IV','V','vi','vii'];
      },
      progression() {
        // https://www.guitarlessonworld.com/lessons/modes/
        // standard progression 
        // minor and major 7th in chords is not the same as in note intervals
        var numeral = this.numeral();
        var chordopt = ['M','m','m','M','M','m','d'];
        var chordopt7th = ['M7','m7','m7','M7','D7','m7','m7b5'];  // minor7 flat5 is half diminished
        // minor progression https://www.musical-u.com/learn/discovering-minor-chord-progressions-minor-chords-part-one/
        var chordoptminor = ['m','d','M','m','m','M','M'];
        // https://www.studybass.com/lessons/harmony/minor-scale-diatonic-chord-qualities/
        var chordoptminor7th = ['m7','m7b5','M7','m7','m7','M7','D7']; // minor7 flat5 is half diminished
      },
      // append the following suffixes on notes in order to make the chords at I ii ii IV V ...
      minorProgression() {
        return ['m','dim','','m','m','',''];
      },
      minor7thProgression() {
        return ['m7','m7b5','M7','m7','m7','M7','7'];
      },
     major7thProgression() {
        return ['M7','m7','m7','M7','D7','m7','m7b5'];
      },
      majorProgression() {
          return ['','m','m','','','m','dim'];
      },

      buildProgession() {
          // used named as the key
          //console.log('buildProgession',this.named,'tonic:', this.tonic(), 'isMinor:', this.isMinor(),'isSeventh:',this.isMajorSeventh() || this.isDominantSeventh());
          var steps = [];
          if (!this.isMinor()) {
            steps = this.ionian();
          } else {
            steps = this.aeolian();
          }
          var out = [];
          var curStep = 0;
          var tonicIdx = this.noteIndex(this.tonic());
          out = steps.map(item => {curStep+=item;return this.noteAtIndex(tonicIdx + curStep);});
          out.unshift(this.noteAtIndex(tonicIdx)); // add the tonic to start, 
          out.pop(); // rem octave from end
          //add chord opt
          if (!this.isMinor()) {
            if (this.isMajorSeventh()) {
              out = out.map((item,idx) => item + this.major7thProgression()[idx]);
            } else { 
              out = out.map((item,idx) => item + this.majorProgression()[idx]);
            } 
          } else {
            if (this.isMinorSeventh()) {
              out = out.map((item,idx) => item + this.minor7thProgression()[idx]);
            } else {
              out = out.map((item,idx) => item + this.minorProgression()[idx]);
            }  
          }
          return out;
      },
      shapes() {
        // most familiar/comfortable shapes for forming chords
        var out = [
          ['a','X02220'],
          ['c','X32010'],
          ['d','XX0232'],
          ['e','022100'],
          ['f','XX3211'],
          ['g','320003'],
          ['a7','X02020'],
          ['b7','X21202'],
          ['d7','XX0212'],
          ['am','X02210'],
          ['gm7','XX3333']
        ];
        return out;
      },
      noteDiff(a,b) {
        return this.noteIndex(a) - this.noteIndex(b);
      },

      noteInterval(a,b) {
        // 12 notes, 11 is high index
        var ia = this.noteIndex(a);
        var ib = this.noteIndex(b);
        var out = -1;
        //console.log('note interval',a,ia,b,ib);
        if (ia > -1 && ib > -1) {
          if (ia<ib) {
            out = ib - ia;
          } else {
            out = (this.allnotes().length - ia) + ib;
          }  
        }  
        if (out >=0 ) {
          return (this.intrvl()[out]);
        } else {
          return out;
        }
      },

      ionian() {
        // major scale
        // happy
        return [2,2,1,2,2,2,1];
      },
      dorian() {
        // soulful
        return [2,1,2,2,2,1,2];
      },
      phrygain() {
        // flamenco
        return [1,2,2,2,1,2,2];
      },
      lydian() {
        // bright
        return [2,2,2,1,2,2,1];
      },
      mixolydian() {
        // bluesy
        return [2,2,1,2,2,1,2];
      },
      aeolian() {
        // minor scale
        // sad
        return [2,1,2,2,1,2,2];
      },
      locrian() {
        // sinister
        return [1,2,2,1,2,2,2];
      },

      transpose(semitones) {
         var out = [];
         let idx= this.noteIndex(this.tonic());
         out.push(this.noteAtIndex(idx + semitones).toUpperCase());
          // remove tonic and bass
          //console.log('trans',this.tonic(),'idx',idx + semitones,this.noteAtIndex(idx + semitones));
         let midPart = this.named.replace(/\/.+/,'').replace(/^[a-g](b|#)?/i,'');
         out.push(midPart);
         if (this.getBass()) {
            let bassIdx= this.noteIndex(this.getBass());
            out.push('/');
            out.push(this.noteAtIndex(bassIdx + semitones).toUpperCase());
         }
         return out.join('');
      },

      noteIndex(inNote) {
        if (inNote) {
          
          var note = inNote.toString().toLowerCase().replace(/[^a-g#]/g,'').substring(0,2);
          if (note.charAt(1) === 'b') { //flat
            var relIndex = this.allnotes().indexOf( note.charAt(0) ) - 1;
            if (relIndex < 0 ) {
              relIndex = this.allnotes().length - 1;
            } 
            note = this.allnotes()[relIndex] ;//+ '#';
            //console.log('noteIndex',inNote,note);
          }
          return this.allnotes().indexOf(note);
        } else {
          return -1;
        }
      },
      tonic() {
        return this.root();
      },

      root () {
        //tonic
        var out = this.named[0].toLowerCase();
        if (this.named[1] === "#") {
          out += this.named[1];
        } else if (this.named[1] === "b") {
          var relIndex = this.allnotes().indexOf(out) - 1;
          if (relIndex < 0 ) {
            relIndex = this.allnotes().length - 1;
          }
          out = this.allnotes()[relIndex];
        }
        return out;
      },

      note(index) {
        var out = -1;
        if (!index) {
          out = this.root();
        } else {
          var relIndex = this.allnotes().indexOf(this.root()) + index;
          if (relIndex > this.allnotes().length - 1) {
            relIndex = relIndex % this.allnotes().length;
          }
          out = this.allnotes()[relIndex];
        }
        return out;
      },
      // sus, mM,+,o
      isMinor () {
        // not an i then an 'm' not followed by an 'a'
        return this.named.match(/[^i]m(?!a)/);
        //return this.named.match(/(?<!i)m(?!a)/);
        //Safari doesnt support lookbehind https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
      }, 
      isDiminished() {
        return this.named.match(/(dim|o)/);
      },
      isHalfDiminished() {
        return this.named.match(/(b5|ø)/);
      },
      isAugmented() {
        return this.named.match(/(aug|\+)/);
      },
      isMajorSeventh() {
        return this.named.match(/(Δ|maj|MAJ|Maj|M)7/);
      },
      isDominantSeventh() {
        // return this.named.match(/(?<!maj|MAJ|Maj|M)7/);
        return  this.named.match(/7/) && !this.isMajorSeventh();
        
        //Safari doesnt support lookbehind https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
      },
      isMinorSeventh() {
        return this.named.match(/m7/);
      },
      isSixth() {
        return this.named.match(/6/);
      },
      isDominantNinth() {
        return  this.named.match(/9/) && !this.isMajorNinth() && !this.isAdd9();
        //return this.named.match(/(?<!maj|MAJ|Maj|M|add)9/);
      },
      isMajorNinth() {
        return this.named.match(/(maj|MAJ|Maj|M)9/);
      },
      isMajorEleventh() {
        return this.named.match(/(maj|MAJ|Maj|M)11/);
      },
      isDominantEleventh() {
        return  this.named.match(/11/) && !this.isMajorEleventh() && !this.isAdd11();
      },
      isMinorEleventh() {
        // covered in minor/major root parse
      },
      isMajorThirteenth() {
        return this.named.match(/(maj|MAJ|Maj|M)13/);
      },
      isDominantThirteenth() {
        return  this.named.match(/13/) && !this.isMajorThirteenth();
      },
      isAdd2() {
        return this.named.match(/add2/);
      },
      isAdd4() {
        return this.named.match(/add4/);
      },
      isAdd5() {
        return this.named.match(/add5/);
      },
      isAdd9() {
        return this.named.match(/add9/);
      },
      isAdd11() {
        return this.named.match(/add11/);
      },
      isSuspended2() {
        return this.named.match(/sus2/i);
      },
      isSuspended4() {
        return this.named.match(/sus4?/i);
      },
      getBass() {
        var out = this.named.match(/\/[A-G](#|b)?/ig);
        return out;
      },
      notes(getSeq) {
        var out = [];
        var seq = [];
        // TODO add mM to the below tests

        if (this.isMinor()) {
          out = [this.note(),this.note(3), this.note(7)];
          seq = ['P1','m3','P5'];
        } else if (this.isDiminished()) {
          out = [this.note(),this.note(3), this.note(6)];
          seq = ['P1','m3','d5'];
        } else if (this.isAugmented()) {
          out = [this.note(),this.note(4), this.note(8)];
          seq = ['P1','M3','m6'];
        } else if (this.isHalfDiminished()) {
          out = [this.note(),this.note(4), this.note(6)];
          seq = ['P1','M3','d5'];
        } else {
          out = [this.note(),this.note(4), this.note(7)];
          seq = ['P1','M3','P5'];
        }
        if(this.isSuspended2()) {
          // change the third to a second
          out[1] = this.note(2);
          seq[1] = 'M2';
        } else if (this.isSuspended4()) {
          // change the third to a perfect fourth
          out[1] = this.note(5);
          seq[1] = 'P4';
        }
        if (this.isDominantSeventh()) {
          out.push(this.note(10)); //minor 7th
          seq.push('m7');
        } else if (this.isMajorSeventh()) {
          out.push(this.note(11));
          seq.push('M7');
        } else if (this.isSixth()) {
          out.push(this.note(9));
          seq.push('M6');
        } else if (this.isDominantNinth()) {
          out.push(this.note(10)); //minor 7th
          out.push(this.note(14)); //major 9th
          seq.push('m7');
          seq.push('M9');
        } else if (this.isMajorNinth()) {
          out.push(this.note(11)); //major 7th
          out.push(this.note(14)); //major 9th
          seq.push('M7');
          seq.push('M9');
        } else if (this.isDominantEleventh()) {
          // https://en.wikipedia.org/wiki/Eleventh_chord
          if (seq[1] === 'm3' || seq[1] === 'M3')  {
            out.splice(1,1); // mostly drops the third in this chord bc 3rd-11th is a dissonant combo
            seq.splice(1,1);
          }
          out.push(this.note(10)); //minor 7th
          out.push(this.note(14)); //major 9th
          out.push(this.note(17)); //major 11th
          seq.push('m7');
          seq.push('M9');
          seq.push('P11');
        } else if (this.isMajorEleventh()) {
          if (seq[1] === 'm3' || seq[1] === 'M3')  {
            out.splice(1,1); // mostly drops the third in this chord bc 3rd-11th is a dissonant combo
            seq.splice(1,1);
          }
          out.push(this.note(11)); //major 7th
          out.push(this.note(14)); //major 9th
          out.push(this.note(17)); //major 11th
          seq.push('M7');
          seq.push('M9');
          seq.push('P11');
        } else if (this.isDominantThirteenth()) {
          if (seq[1] === 'm3' || seq[1] === 'M3')  {
            out.splice(1,1); // mostly drops the third in this chord bc 3rd-11th is a dissonant combo
            seq.splice(1,1);
          }
          out.push(this.note(10)); //minor 7th
          out.push(this.note(14)); //major 9th
          out.push(this.note(17)); //major 11th
          out.push(this.note(21)); //major 13th
          seq.push('m7');
          seq.push('M9');
          seq.push('P11');
          seq.push('M13');
        } else if (this.isMajorThirteenth()) {
          if (seq[1] === 'm3' || seq[1] === 'M3')  {
            out.splice(1,1); // mostly drops the third in this chord bc 3rd-11th is a dissonant combo
            seq.splice(1,1);
          }
          out.push(this.note(11)); //major 7th
          out.push(this.note(14)); //major 9th
          out.push(this.note(17)); //major 11th
          out.push(this.note(21)); //major 13th
          seq.push('M7');
          seq.push('M9');
          seq.push('P11');
          seq.push('M13');
        }  
        if (this.isAdd11()) {
          out.push(this.note(17)); //perfect 11th
          seq.push('P11');
        } else if (this.isAdd4()) {
          out.push(this.note(5)); //perfect 4th
          seq.push('P4');
        } else if (this.isAdd5()) {
          out.push(this.note(7)); //perfect 5th
          seq.push('P5');
        } else if (this.isAdd9()) {
          out.push(this.note(14)); //major 9th
          seq.push('M9');
        } else if (this.isAdd2()) {
          out.push(this.note(2)); //major 2nd
          seq.push('M2');
        }     
        if (this.getBass()) {
          // use the note in de to change everything in bass into whitelist note forms
          let bass = this.allnotes()[this.noteIndex(this.getBass()[0].slice(1).toLowerCase())];
          out.unshift(bass);
          // put bass first and find relationship to tonic
          let diff = this.noteDiff(bass,this.root());
          if (diff<0) {
            diff = 12 + diff;
          }
          seq.unshift(this.intrvl()[diff]); 
        } 
        if (getSeq) {
          return seq;
        } else {
          return out;
        }
        
      }
    };
    return out;
  }

  export default chord;