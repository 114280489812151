/*
  X print sheet level 1 (2 cols layout, portrait orientation)
  X encode 3rd, 5th, 7th on finger charts (see color wheel 30 deg)
  X split screen w shapes or long scroll section
  X inline chords
  X mouse over chord change for chord panel
  X instrument specific and color-coded hover stat
  X collapsed chord grouping
  X color-code chord, lyric matches using gra`ys
  X chord hover
  X V-C-V-V readout
  X ...readout w first two words
  X more whitespace between the section to make the view easier
  X incl option of inline chords in addition to verse-chorus-bridge chord summary up top
  X chords need to align with v-c-b printout
  X zoom buttons +/- for detail
  X fix -1 for chord progression (find overall song tonic by all chords in one search and by count of phrase tonics)
  X improve repeat regex  
  
  make the setlist view a scrolling sheet with many songs on specify the level of detail for each, select song from list to set detail  level
  shadows on pressed piano keys
  share list w friend
  set voicing for song


  1. preferences() (user preferences)
  2. Phantom Editor
  2. Songbook (highest level control)
  3. chordParts (not really used now)
  4. ChordView (visual format of textual chord)
  5. SongView 
  6. SongViewMobile (this render() is used, rest inherited from SongView)



*/
import './App.css';
import './dark.css';
import './form.css';
import React from 'react';
import axios from 'axios';
import song from './song';
import chord from './chord';
import {ChordStar,FretMap, FretShape, PianoMap, MusicStaff, HornMap, trumpet, instrument,MIDIPlayer} from './instruments';
import Registration from './register';

function App() {

  return (
    <div className="App"> 
        {1===2 && <MIDIPlayer />}
        {1===2 && <Registration />}
        <Songbook endpoint="https://yumtones.com/query.php" userid={1}/>
    </div>
  );
}

function songOptions() {
  return {
    transpose:0,
    key:'',
    capo:0,
    tags : [],
    voicings: []
  };
}
function urlRoute() {
  // example /user_id/song_id/song_title/play_list/share_token/chord_help/
  // some variation of that 
  // maybe a text search url 
  //history.pushState({obj:1},'Title','/path')
  return {
    user_id:-1,
    song_id:-1,
    song_title:-1,
    play_list:-1,
    share_token:-1,
    chord_help:-1,
    search_text:'',
    browse_mode:-1,
    parseWords :['song','play','search','browse'],
    parseRoute(rt) {
      if (!rt) {
        rt = document.location.href;
      }
      let parts = rt.trim().split(/\//);
      
      let search = parts.indexOf('search');
      if (search > -1) {
        this.search_text = parts[search+1];
      }

      let song = parts.indexOf('song');
      if (song > -1) {
        this.song_id = parts[song+1];
      }

      let play = parts.indexOf('play');
      if (play > -1) {
        this.play_list = parts[play+1];
      }
      
      let chd = parts.indexOf('chord');
      if (chd > -1) {
        this.chord_help = parts[chd+1];
      }
      
      let browse = parts.indexOf('all');
      if (browse > -1) {
        this.browse_mode = true;
      }
      // absolute paths or relaitve (ie incl used_id at each root?)
      // /song/song_id  parse all this out of the url to set the path 
      // /search/title_str
      // /play/playlist-id/token?
      // /browse/ 
      return this;
    },
    playlist() {
      // is okay token?
      // everything alpha should be proceed w a tag name like search: play:  or /search/ /play/
    },
    song() {

    }, 
    search() {
      // look in  url parts for search command
    }
  }.parseRoute();
}
function preferences() {
  return {
    history : [],
    tags : [],

    setlistNames: {'xyz123':'good ones','dskjhas' : 'campfire'}, // eg {setName:[song,song2,song3]} NOT YET IMPLEMENTED or PERHAPS  , 
    setlistSongs: {'xyz123':[2,12]},
    // if use space in "name of key" have to enclose in quotes, consider Map() so can iterate over keys (however no serialization)
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
    // Object.keys(setlists) or Object.entries(setlists) to iterate key val pairs
    // setlist needs a numeric index in case user wants to rename, try a unique id method for the key
    instrument : 'guitar',
    detailVoicing : 'full',
    detailLyric: 'full',
    setSendVals : 'history,tags,instrument,detailLyric,detailVoicing'.split(/,/),
    addTag(name) {
      var format = name.replace(/[^a-z -]/gi,'');
      if (this.tags.indexOf(format) === -1) {
        this.tags.push(format);
      }
      return format;
    },
    send() {
      var out = {};
      this.setSendVals.map(p => { out[p] = this[p]; return true; }); // reduce likely a better choice here
      //out.history = []; // reset values
      return out;
      //return {history : this.history, instrument : this.instrument, detailLyric : this.detailLyric, detailVoicing: this.detailVoicing}
    },
    set(props) {
      if (props) {
        /* loop sets vals like this: this.history = props.history, this.instrument = props.instrument;  etc; */
        this.setSendVals.map(p => { if (props[p]) this[p] = props[p]; return true; });
      }  
    }
  };
}

class PhantomEditor extends React.Component {
  // this editor included a hidden textarea and formats monospaced font for prettier purpose-build song editing
  // TODO include color-coding for control lines and chord lines, consider color code for verse/chorus/bridge, consider realtime feedback of chord factor, consider navigation  
  constructor(props) {
    super(props);
    this.state = {rawText : this.props.value,textSelection:[0,0]}; 
    this.textareaRef = React.createRef();
  }
  componentDidUpdate(prevProps) {
    // loaded new text, replace the editor state text immediately 
    if (prevProps.value !== this.props.value) {
      // console.log('PE NO match');
      this.setState({rawText : this.props.value}, () => this.placeCursor(0,0));
    } else {
      // console.log('PE match');
    }
  }

  placeCursor = (line,chr) => {
    // these is going to change the cursor in the textarea which will be hidden but functionally responsive to the user's edits
    let start  = this.charIndex(line,chr);
    let end = this.charIndex(line,chr);
    this.setState({textSelection:[start,end]});
    // console.log('placeCursor',line, chr,'charIndex', this.charIndex(line,chr));  
    this.textareaRef.current.setSelectionRange(start , end);
    this.textareaRef.current.blur(); // must blur first otherwise will not scroll properply
    this.textareaRef.current.focus();
  }

  lineCss(ln) {
    var out  = song().lineType(ln) || '';
    out = 'line' + out[0].toUpperCase() + out.substr(1);
    return out;
  }
  charIndex = (line, chr) => {
    var out = this.state.rawText.split('\n').slice(0,line).join('\n').length + chr + 1;
    return out;
  }
  textChange = (ev) => { 
    this.props.onChange?.(ev.target.value);
    this.setState({rawText: ev.target.value,textSelection:[ev.target.selectionStart,ev.target.selectionEnd]});
  }  
  // TODO color code these structures for ease of viewing using css classes .ctrlLine,.chrdSpan,.altPara
  render() {
    return(<div className="phantomEditor">
          
          <div className="phantomDisplay" >{this.state.rawText.split(/\n/).map((line,lineNo) => <div key={lineNo} className={[this.lineCss(line)].join(' ')}><div className="lineStatus"><span>{song().lineType(line)}</span></div>{line.split('').map((chr,chIdx) => <b key={chIdx} className={this.charIndex(lineNo,chIdx) >= this.state.textSelection[0] && this.charIndex(lineNo,chIdx) <= this.state.textSelection[1] ? 'on' : ''} onClick={() => this.placeCursor(lineNo,chIdx)}>{chr !== ' ' ? chr : <span>&nbsp;</span>}</b>)}<b className={this.charIndex(lineNo+1,-1) >= this.state.textSelection[0] && this.charIndex(lineNo+1,-1) <= this.state.textSelection[1] ? 'on' : ''} onClick={() => this.placeCursor(lineNo+1,-1)}>&nbsp;</b></div>)}</div>
          <textarea onBlur={(ev) => {ev.preventDefault();  ev.currentTarget.focus();}} autoFocus={true}  ref={this.textareaRef} onChange={this.textChange } placeholder={this.props.placeholder}  value={this.state.rawText} onKeyUp={(ev) => {ev.preventDefault();this.setState({textSelection:[ev.target.selectionStart,ev.target.selectionEnd]})} } onClick={(ev) => {ev.preventDefault();this.setState({textSelection:[ev.target.selectionStart,ev.target.selectionEnd]})} }></textarea>

    </div>);
  }

}  

class Songbook extends React.Component {
  constructor (props) {
    super(props);
    this.state = {allText:'', readout: 'play', mode : 'play', expand : false, songlist : [], viewsong : {}
    , chordSearch : 'Gm7', selectedTag : false, detailLyric:this.detailLevels()[0], detailVoicing: this.detailLevels()[0], size : 's'
    , browse : false, songsearch : '', preferences : preferences(),loaded:false, chordContext:["","",""], winHeight:0,songBookHt:0, curScrollY:0,songIndex:[]};
    this.load();
  }
  lineCss(ln) {
    var out  = song().lineType(ln) || '';
    out = 'line' + out[0].toUpperCase() + out.substr(1);
    return out;
  }
  detailLevels(detail) {
    // pass current level to increment to next
    let idx = -1; 
    //var out =  ['full','more','less','none'];
    var out =  ['full','less','none'];
    if (detail) {
      idx = out.indexOf(detail);
    }
    idx++;
    if (idx >= out.length) {
      idx = 0;
    }
    if (detail) {
      return out[idx];
    } else {
      return out;
    }
  }
  handleScroll = (ev) => {
    // keep the factored chords at the top of the screen when there are more verses w same chords to follow
    //console.log('scrolling',window.scrollY);
    this.setState({curScrollY: window.scrollY});
  }
  componentDidMount() {
    window.addEventListener('keyup', this.handleKey); 
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKey);
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.handleScroll);
  }
  componentDidUpdate() {
    this.updateWindowDimensions();
  }
  updateWindowDimensions = () =>  {
    // this is intended to make a clickable mini map to aid in quick scrolling to diff chunks when playing, however the 
    // need to test the values so no state update endless loop
    if (this.state.winHeight !== window.innerHeight || this.state.songBookHt !== this.songBookEl.clientHeight) {
      this.setState({ winHeight: window.innerHeight, songBookHt : this.songBookEl.clientHeight });
    }
  }
  exportiRealPro() {
    // https://www.irealpro.com/ireal-pro-file-format
    // SongTitle=LastName FirstName=Style=Key=n=chordProg
    // chord prog is 16 cells per line, max 12 lines 
    // 4 cells per measure 

  }
  /* valid iRealPro chord qualifities 
  
  5, 2, add9, +, o, h, sus, ^, -, ^7, -7, 7, 7sus, h7, o7, ^9, ^13, 6, 69, ^7#11, ^9#11, ^7#5, -6, -69, -^7, -^9, -9, -11, -7b5, h9, -b6, -#5, 9, 7b9, 7#9, 7#11, 7b5, 7#5, 9#11, 9b5, 9#5, 7b13, 7#9#5, 7#9b5, 7#9#11, 7b9#11, 7b9b5, 7b9#5, 7b9#9, 7b9b13, 7alt, 13, 13#11, 13b9, 13#9, 7b9sus, 7susadd3, 9sus, 13sus, 7b13sus, 11
  */
  handleKey = (e) => {
    // disable while working on textbox stuff , probably capture bubble in textbox specific code to avoid trigger here TODO
    if (this.state.mode === 'play' && this.state.phantomText === '') {
      if (e.keyCode === 37) { //left
        this.prev();
      } else if (e.keyCode === 39) { //right
        this.next();
      } else if (e.keyCode === 38) { //up
         // think this or the space to scroll bc it is going to come up
      } else if (e.keyCode === 40) { //down
         // think this or the space to scroll bc it is going to come up
      } else if (e.key === 'v') {
        this.cycleVoicing();
      }  else if (e.key === 'l') {
        this.cycleLyrics();
      } else if (e.key === 'f') {
        this.toggleFullScreen();
      } else if (e.key === 'p') {
        this.printMode(); // not used
      } else if (e.key === 'g') {
        this.setMode('search');  
      } else if (e.key === ' ') {
        // spacebar advances the scroll point
        e.preventDefault();
        this.nextScrollPoint();
      }  
    }
    
  }
  buildSonglist = (respData) => {
    // process request data in song object
    return respData.map(song => {
      song.options = JSON.parse(song.optionjson);
      if (!song.options) {
        song.options = songOptions();
      }
      return song;
    });
  }
  load(songid) {
    // songid params is passed after a new song is added and it is saved the list is reloaded to include the new song (w id)
    let userUrl = this.props.endpoint + '?mode=user&id=' + this.props.userid;
    let songUrl = this.props.endpoint + '?mode=list';
    //userUrl = 'user.json'; // local dev only
    //songUrl = 'songs.json'; // local dev only
    axios.get(userUrl).then(resp => {
      //console.log('preference load', resp.data.preferences);
      var prefs = this.state.preferences;
      prefs.set(JSON.parse(resp.data[0].preferences));
      this.setState({preferences : prefs});
    });
    axios.get(songUrl).then(resp =>       { 
      let sL = this.buildSonglist(resp.data); 
      console.log('load, buildSongList',sL);
      this.setState({songlist : sL,loaded:true}, () => this.initialSong(songid) ); }         );
    
  }
  initialSong = (songid,attempt) => {
    // load the initial song from param/history/top of sorted list

    // this is a time to reindex
    this.indexAllSongs();
    // no prefs yet? wait a sec, but only one
    if (!this.state.preferences && !attempt) {
      setTimeout(() => this.initialSong(songid,1),900);
      return false;
    }
    let songObj = false;
    // if no song specified, take the top one from the history
    if (!songid && this.state.preferences.history.length > 0) {
      songid = this.state.preferences.history[0];
    }
    if (songid) {
      songObj = this.state.songlist.filter(s => parseInt(s.id) === parseInt(songid))[0];
    }
    // take the top song
    if (!songObj) {songObj = this.sortedsongs()?.[0]?.ref }
    console.log('initialSong',songObj);
    // select song but do not add a history entry for this
    if (songObj) {
      this.songSelect(songObj,true);
    }  
  }
  saveprefs = () => {
    // save the preferences to the server, limit saves for heavy user activity
    clearTimeout(this.savePrefsTimer);
    this.savePrefsTimer = setTimeout(this.postprefs,3500);   
  }
  postprefs = () => {
    var params = {preferences: this.state.preferences.send(), id : this.props.userid };
    axios.post(this.props.endpoint + '?mode=saveprefs',params).then(resp => true);
  }
  restore = () => {
    // set the current song text to the original song text, will not save until user saves
    var okay = window.confirm('Load initial version of song into editor?');
    if (okay) {
      let vs = this.state.viewsong;
      // add a space to trigger a value change in the editor which refreshes state
      vs.songtext = vs.origtext;
      this.setState({viewsong : vs});
    }
  }
  delete = () => {
    if (window.confirm('Delete song? Sure?')) {
      let nextSongId = this.next(true);
      axios.post(this.props.endpoint + '?mode=delete',{id:this.state.viewsong.id}).then(resp => {this.load(nextSongId)});
      // TODO instead of load, just rem song from playlist 
      
      this.setState({'mode' : 'play'});
    }
  }
  add = (inSong) => {
    let songText = inSong || this.state.allText;
    if (songText.length) {
      axios.post(this.props.endpoint + '?mode=add',{songtext:songText}).then(resp => {console.log('resp',resp.data);this.load(resp.data);this.setMode('play');this.timedReadout('addcomplete','play');});
    }  
  }

  assignTag = (id, newT) => {
    // perhaps this is more a feature of song()
    // add tag to id
    var sL = this.state.songlist;
    var tSongIdx = sL.reduce((acc, item, idx) => item.id === id ? idx : acc ,-1);
    // console.log('tSongIdx',tSongIdx,id,newT);
    var tSong = sL[tSongIdx];
    var opt = (tSong.options) ? tSong.options : songOptions();
    opt.tags.indexOf(newT) === -1 && opt.tags.push(newT);
    tSong.options = opt;
    this.setState({songlist : sL},this.indexAllSongs);
    this.save(tSong);
    
  }
  viewSongOptions() {
    // TODO replace this functionality in assignTag, transpose using this method
    var song = this.state.viewsong;
    var opt = (song.options) ? song.options : songOptions();
    return opt;
  }
  updateViewSong(newOptions,newText) {
    // TODO replace this functionality in assignTag, transpose() using this method
    let songList = this.state.songlist;
    var curIdx = songList.reduce((acc,item,idx) => item.id === this.state.viewsong.id ? idx : acc,-1);

    if (newOptions !== false) {
      songList[curIdx].options = newOptions;
    }      
    if (newText) {
      songList[curIdx].songtext = newText;
    }
    this.setState({viewsong: songList[curIdx], songlist: songList},this.indexAllSongs);
    this.save(songList[curIdx]);
     
  }
  setVoicing = (chd, newVcng) => {
    // add a parameter to the viewsong of voicings like [['C','-1,3,2,0,1,0']]
    if (!chd) chd = this.state.chordHelp;
    console.log('voicings',this.state.chordHelp,newVcng);
    var opt = this.viewSongOptions();
    if (!opt.voicings) {
      opt.voicings = [];
    }
    
    var vcngIdx = opt.voicings.reduce((acc,vcng,idx) => acc === -1 && vcng[0] === chd ? idx : acc,-1);
    if (vcngIdx === -1) {
      if (newVcng) {
        // false input means to clear a voicing
        opt.voicings.push([chd,newVcng.join(',')]);
      }
    } else {
      // false input means to clear a voicing
      if (newVcng) {
        opt.voicings[vcngIdx] = [chd,newVcng.join(',')];
      } else {
        // remove that entry
        opt.voicings.splice(vcngIdx,1);
      }  
    }
    // find if a voicing for the current chord exists in the list
    this.updateViewSong(opt);
    
  }
  transpose = (steps) => {
    // set song-level preference of transpose, 
    var song = this.state.viewsong;
    var opt = (song.options) ? song.options : songOptions();
    opt.transpose += steps;
    if (!steps) {opt.transpose = 0};
    song.options = opt;
  
     // add to viewSong and songList, 
    let songList = this.state.songlist;
    var curIdx = songList.reduce((acc,item,idx) => item.id === this.state.viewsong.id ? idx : acc,-1);
    songList[curIdx] = song;
    this.setState({viewsong: song, songlist: songList});

    // then save options to database for song options
    //console.log('transpose', opt.transpose);
    //apply to all displayed chords and the key
  }

  addTag = () => {
    var pref = this.state.preferences;
    // TODO use pref.addTag here
    if (pref.tags.filter(t => t.toLowerCase() === this.state.phantomText.toLowerCase()).length === 0) {
      pref.tags.push(this.state.phantomText);
      this.setState({phantomText : '', preferences : pref}, this.saveprefs );
    }
  }

  removeTag = () => {
    // remove a tag from preferences (and all songs? )
    if (window.confirm('Want to remove this setlist? Sure?')) {
      var pref = this.state.preferences;
      pref.tags = pref.tags.filter(t => t !== this.state.selectedTag);
      // remove from songs
      var songL = this.state.songlist;
      songL.forEach(song => {
        let found = song.options.tags.filter(t => t === this.state.selectedTag).length > 0;
        if (found) {
          song.options.tags = song.options.tags.filter(t => t !== this.state.selectedTag);// filter out the current tag from the options list
          // console.log('found Song',song);
          this.save(song); // update datebase
        }
      }); 
      this.setState({songlist:songL,selectedTag :false, preferences : pref},() => {this.saveprefs();this.indexAllSongs();});
    }
  }

  removeTagFromSong = (selT) => {
    // function removes the selected tag from the selected song
    var songL = this.state.songlist;
    var editSong = this.state.songlist.filter(song => song.id === this.state.viewsong.id )[0]; // get the effected song
    if (!selT) {
      selT = this.state.selectedTag;
    }
    editSong.options.tags = editSong.options.tags.filter(tag => tag !== selT); // filter out the current tag from the options list
    this.state.songlist.forEach(song => song.id === editSong.id ? editSong : song);// readd to state var
    this.save(editSong); // update datebase

    this.setState({songlist:songL},this.indexAllSongs);
  }  
  customVoicing()  {
    var out = [this.state.chordHelp,this.state.phantomText];
    //this.setState({phantomText:false});
    //console.log('addVoicing', out);
    let sTxt = song(this.state.viewsong.songtext).addVoicing(this.state.chordHelp,this.state.phantomText);
    this.updateViewSong(false,sTxt);
    
  }
  save = (saveSong) => {
    if (!saveSong) {
      saveSong = this.state.viewsong;
    }
    let params = {songtext : saveSong.songtext, optionjson : saveSong.options, id : saveSong.id};
    axios.post(this.props.endpoint + '?mode=update', params).then(resp => true);
  }

  next = (onlyID) => {
    var sL = this.sortedsongs();
    var curIdx = sL.reduce((acc,item,idx) => item.id === this.state.viewsong.id ? idx : acc,-1);
    curIdx++;
    if (curIdx >= sL.length) {
      curIdx = 0;
    }
    if (onlyID !== true) {
      // sometimes we just want the id, not to select it 
      this.songSelect(sL[curIdx].ref);
    }
    return sL[curIdx].id; // return the id of the next song
  }
  prev = () => {
    var sL = this.sortedsongs();
    var curIdx = sL.reduce((acc,item,idx) => item.id === this.state.viewsong.id ? idx : acc,-1);
    curIdx--;
    if (curIdx < 0 ) {
      curIdx = sL.length -1;
    }
    this.songSelect(sL[curIdx].ref);
    
  }
  search = (event) => {
    var newVal = event.target.value;
    this.setState({songsearch : newVal});
  }
  paste = () => {
    navigator.clipboard.readText().then(
      clipText => this.add(clipText));
  }



  setInstrument = (newInstrument) => {
      var instruments = ['guitar','piano','trumpet'];
      if (!newInstrument) {
        let idx = instruments.indexOf(this.state.preferences.instrument);
        if (idx === -1) {
          idx = 0;
        } else {
          idx++;
          if (idx >= instruments.length) {
            idx =0;
          }
        }
        newInstrument = instruments[idx];
      }
      var pref = this.state.preferences;
      pref.instrument = newInstrument;
      this.setState({preferences : pref});
      this.saveprefs();
      this.timedReadout('instrument');
  }
  readout = (newReadout) => {
    // generally only set when changing view modes
    clearInterval(this.readOutTimer);
    this.lastReadout = newReadout; // for revert on timeReadout
    this.setState({readout: newReadout || 'play'});
  }
  timedReadout = (newReadout, nextReadout) => {
    // generally set by user actions and then reverts back to view mode default
    // only update lastReadout if is explicitly pass or we are NOT already in a timer situation
    var last = nextReadout || (!this.readOutTimer && this.state.readout);
    if(last !== newReadout && last) { this.lastReadout = last; }
    this.setState({readout: newReadout || 'instrument'});
    clearInterval(this.readOutTimer);
    this.readOutTimer = setTimeout(() => this.setState({readout:this.lastReadout}), 2000);
  } 
  toggleFullScreen = () => {
    if (!document.fullscreenElement) {
        
        document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  cycleLyrics = () => {
    var nextLevel = this.detailLevels(this.state.preferences?.detailLyric || this.detailLevels()[0]);
    let prefs = this.state.preferences;
    prefs.detailLyric = nextLevel;
    this.setState({detailLyric : nextLevel, preference:prefs},  this.saveprefs);
    this.timedReadout('lyricview');
  }

  cycleVoicing = () => { 
    var nextLevel = this.detailLevels(this.state.preferences?.detailVoicing || this.detailLevels()[0]);
    let prefs = this.state.preferences;
    prefs.detailVoicing = nextLevel;
    this.setState({detailVoicing : nextLevel, preference:prefs},  this.saveprefs);
    this.timedReadout('voicingview');
  }

  songSelect = (nextsong, noHistory) => {
    // pass a song to make it current, avoid saving history on load using noHistory param
      this.setMode('play');
      console.log('songSelect nextsong',nextsong);
      document.title = song(nextsong.songtext).titleFormat();
      if (noHistory) {
        this.setState({viewsong : nextsong, phantomText:''});

      } else {
        let prefs = this.state.preferences;
        prefs.history.unshift(parseInt(nextsong.id));
        prefs.history = prefs.history.slice(0,20); // only keep last 20 history entries
        this.setState({viewsong : nextsong, browse : false,preference:prefs, phantomText:''},this.saveprefs);
      }
      window.scrollTo({top:0});
      
  }

  chordHelp = (chord, previous, next) => {
    clearInterval(this.readOutTimer);
    if (chord) {
//      this.readout('chordhelp');
      if (!previous) previous = "";
      if (!next) next = "";
      this.setState({chordHelp: chord, chordContext: [previous.replace(/[\[\]]/g,'').trim(), chord , next.replace(/[\[\]]/g,'').trim()]   });
      
    } else {
//      this.readout('play');
      //this.setState({chordHelp: false});
     
    }
  }
  printMode = () => {
    (this.state.mode === 'print') ? this.setMode('play') : this.setMode('print');
  }
  up = () => {
    var newMode = this.state.mode === 'browse' ? 'play' : 'browse';
    this.setMode(newMode);
  }
  addMode = () => {
    
    navigator.clipboard.readText().then(  clipText => this.addClip(clipText)  ) ;
    clearInterval(this.addInt);
    this.addInt = setInterval(this.addMode,1500 ); // probably needs to be retriggered by a user action
  }
  setMode(switchMode) {
    
    this.setState({mode : switchMode,phantomText:''});
    this.readout(switchMode);
  }
  addClip = (clipText) => {
    let validClip = song(clipText).valid();
    if (validClip) {
      clearInterval(this.addInt);
      this.add(clipText);
    } else {
      this.setMode('add')
      this.setState({allText:clipText});
    }
  }

  readoutDisplay() {
    return(
      <div className="readout">
            {this.state.readout === 'add' && <h4>Add Song</h4>}
            {this.state.readout === 'add' && <div className="readoutGuide colorB"> Select all and copy from source song text.</div>}
            {this.state.readout === 'addVoicing' && <h4>Add Voicing for {this.state.chordHelp}</h4>}
            {this.state.readout === 'crop' && <h4>Crop Song</h4>}
            {this.state.readout === 'debug' && <h4>Debug Mode</h4>}
            {this.state.readout === 'edit' && <h4>Edit Song</h4>}
            {this.state.readout === 'edit' && <div className="readoutGuide colorB">Type song text, use labels to divide chorus, verse, bridge</div>}

            {this.state.readout === 'setlist' && <h4>Setlists</h4>}
            {this.state.readout === 'newSetlist' && <h4>Add Setlist</h4>}
            {this.state.readout === 'print' && <h4>Print</h4>}
            
            {this.state.readout === 'songAddSetlist' && <h4>Add Song to Setlist</h4>}
            {this.state.readout === 'songAddSetlist' && <div className="readoutGuide colorB">Select Setlist</div>}

            {this.state.readout === 'source' && <h4>Source</h4>}
            {this.state.readout === 'voicing' && <h4>Voicings</h4>}

            {(this.state.readout === 'play' || this.state.readout === 'voicing') && <React.Fragment>
            <div>
            <div className="readoutTitle">{ song(this.state.viewsong.songtext).titleFormat()}</div>
            
            </div>
            <div className="keyCapo"> 
              <h4>key</h4>
              <span className="colorE">{song(this.state.viewsong.songtext).key().join(', ')}</span>
              {this.state.viewsong.options && this.state.viewsong.options.transpose !== 0 && <h4>{this.state.viewsong.options.transpose > 0 ? '+' + this.state.viewsong.options.transpose : this.state.viewsong.options.transpose}</h4>}
              {song(this.state.viewsong.songtext).capo() > 0 && <h4>CAPO <span className="colorE">{song(this.state.viewsong.songtext).capo()}</span></h4>}
            </div>
            </React.Fragment>}   

            {this.state.readout === 'instrument' && <div>
                <h4>Chord Map Instrument</h4>
              <ul className="colorE choice">
                <li className={( this.state.preferences.instrument === 'guitar' && 'on') || ''}>Guitar</li>
                <li className={( this.state.preferences.instrument === 'piano' && 'on') || ''}>Piano</li>
                <li className={( this.state.preferences.instrument === 'trumpet' && 'on') || ''}>Trumpet</li>
              </ul>
            </div>}

            {this.state.readout === 'lyricview' && <div>
                <h4>Lyric View</h4>
                <ul className="choice colorD">
                  {this.detailLevels().map(l => <li className={this.state.preferences?.detailLyric === l && 'on'}>{l}</li>)}
               </ul>
            </div>}


            {this.state.readout === 'voicingview' && <div>
                <h4>Voicing View</h4>
                <ul className="choice colorD">
                  {this.detailLevels().map(l => <li className={this.state.preferences?.detailVoicing === l && 'on'}>{l}</li>)}
               </ul>
            </div>}

            
            {this.state.readout === 'addcomplete' && <div><div className="readoutTitle colorB">Song Added</div></div>}

            
            {(this.state.readout === 'browse' || this.state.readout === 'search' ) && <div style={{display:'block'}}>{this.sortedsongs().length} Songs Found. Search by title or author.
                {1===2 && <div>
                <h4>Search for Song</h4>
                <div className="readoutSearchText colorB">{this.state.songsearch}</div>
                <div className="readoutSearch"><input type="text" placeholder="Search Songs" value={this.state.songsearch} onChange={this.search} /></div>
                </div>}
            </div>}
            {(this.state.readout === 'browse' || this.state.readout === 'search' || this.state.readout === 'newSetlist' || this.state.readout === 'addVoicing' )&&  <div className="readoutTextInput">{this.state.phantomText?.split('').map((l,idx) => <span className={this.state.phantomSelection?.[0] <= idx && this.state.phantomSelection?.[1] >= idx && 'caret'}>{l === ' ' ? <span>&nbsp;</span> : l}</span>)}{( this.state?.phantomSelection?.[0] >= this.state.phantomText?.length || !this.state.phantomText?.length ) && <span className="caret">&nbsp;</span>}</div>}

            <div className="seltag">{this.state.selectedTag && (this.state.selectedTag)} {this.state.songlist.filter(song => song.options.tags.filter(t => t === this.state.selectedTag).length > 0).map((song,idx) => <span className={song.id === this.state.viewsong.id ? 'on' : ''}></span> )}</div>

            <ul className="buttonHelp markers">
              {this.state.mode === 'browse' && <li className="on colorB"></li>}
              {this.state.mode === 'edit' && <li className="on colorB"></li>}
              {this.state.mode === 'voicing' && <li className="on colorE"></li>}
              {this.state.mode === 'newSetlist' && <li className="on colorB"></li>}
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            
            {this.state.readout === 'buttonhelp' &&  this.state.mode === 'play' && 
            <ul className="buttonHelp category">
              <li className="colorB txtL">song</li>
              <li></li>
              <li className="colorE">tone</li>
              <li className="colorD">view</li>
              <li></li>
              <li className="colorB txtL"></li>
              <li className="colorE"></li>
            </ul>}
            {this.state.readout === 'buttonhelp' &&  this.state.mode === 'play' && 
            <ul className="buttonHelp">
              <li>all</li>
              <li>edit</li>
              <li >map</li>
              <li>chord</li>
              <li>lyric</li>
              <li>prev</li>
              <li>next</li>
            </ul>}
            {this.state.readout === 'buttonhelp' &&  this.state.mode === 'browse' && 
            <ul className="buttonHelp">
              <li>exit</li>
              <li></li>
              <li></li>
              <li>add song</li>
              <li>setlist</li>
              <li></li>
              <li>prefs</li>
            </ul>}
            {this.state.readout === 'buttonhelp' &&  this.state.mode === 'crop' && 
            <ul className="buttonHelp">
               <li>cancel</li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li>crop</li>
            </ul>}
            {this.state.readout === 'buttonhelp' &&  this.state.mode === 'edit' && 
            <ul className="buttonHelp">
              <li>cancel</li>
              <li>set list</li>
              <li></li>
              <li>revert</li>
              <li>rem</li>
              <li></li>
              <li>save</li>
            </ul>}
            {this.state.readout === 'buttonhelp' &&  this.state.mode === 'newSetlist' && 
            <ul className="buttonHelp">
              <li>cancel</li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li>add</li>
            </ul>}
            {this.state.readout === 'buttonhelp' &&  this.state.mode === 'search' && 
            <ul className="buttonHelp">
               <li>cancel</li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>}
            {this.state.readout === 'buttonhelp' &&  this.state.mode === 'voicing' && 
            <ul className="buttonHelp">
               <li>cancel</li>
                <li></li>
                <li></li>
                <li></li>
                <li>{chord(song(this.state.viewsong.songtext).key()[0]).transpose(-1)}</li>
                <li>{song(this.state.viewsong.songtext).key()[0]}</li>
                <li>{chord(song(this.state.viewsong.songtext).key()[0]).transpose(1)}</li>
            </ul>}
          </div>
    );
  }
  indexAllSongs() {
    // TODO ensure this is updates a saves and catalog edits / song edits
    // makes loading list a lot faster to render these results each time
    var out = this.state.songlist.map(sng => this.indexSong(sng));
    console.log('indexAllSongs',out,'state.songlist',this.state.songlist);
    this.setState({songIndex : out});
    
  }
  indexSong(inSong) {
    // caches a single song into a catalog class to reduce calculations require on the Songbook renders
    var songObj = song(inSong.songtext);
    // list view has title, artist,key,progressionVI,and verseChordBridge, then pass object onto song select
    var out = {
        id:inSong.id,
        title: songObj.titleFormat() || 'None',
        url: '#' + songObj.title().replace(' chords by ',' - '),
        artist: songObj.artist() || 'None',
        key: songObj.key().join(','),
        capo: songObj.capo(),
        verseChorusBridge: songObj.verseChorusBridge().join('-'),
        progression: songObj.progressionVI().slice(0,1),
        options: inSong.options,
        ref: inSong // prob a better way than to pass a ref and embed the obj TODO
    };
    return out;
  }
  sortedsongs() {

    //var out = this.state.songlist;
    var out = this.state.songIndex; // return a cached list instead of recalc each time 
    console.log('this.state.songIndex',out);
    var srch = this.state.phantomText;//this.state.songsearch;
    console.log('sortedsongs srch',srch, 'selTag',this.state.selectedTag);
    if (srch) {
      out = out.filter(item => item.title.toLowerCase().indexOf(srch.toLowerCase()) > -1 || item.artist.toLowerCase().indexOf(srch.toLowerCase()) > -1 ); // text search
    }  
    if (this.state.selectedTag) {
      out = out.filter(item => item.options.tags.indexOf(this.state.selectedTag) !== -1);
    }
    console.log('sortedsongs',out);
    out.sort((a,b) => ('' + a.title).localeCompare(b.title));
    return out;
  }
  cropRange(start,end) {
    if (start === false) {
      // use value that is already there for start

    }
    if (end === false) {
      // use value that is already there for end
    }
    console.log('song crop',start,end);
  }
  /*
  // possible rem
  searchNav = () => {
    return(
    <nav>
      <button className="material-icons-outlined colorB" onClick={() => {this.setMode('browse');this.setState({phantomText:''})}}><span>close</span></button>
      <button  className="fake material-icons-outlined col5"><span>fake</span></button>
      <button  className="material-icons-outlined colorB"  onClick={() => {this.setMode('browse');this.setState({phantomText:''})}}><span>check</span></button>
    </nav>);
  }
  */
  browseNav = () => {
    return(
      <nav>
        <button onClick={this.up} className={['material-icons-outlined colorB browse', this.state.mode === 'browse' ? 'on' : ''].join(' ')} title="songs"><span>close</span></button> 
        {1===2 && <button onClick={() => this.setMode('search')} className="material-icons-outlined colorB" title="view"><span>search</span></button>}
        <button className="fake material-icons-outlined"><span>fake</span></button>
        <button className="fake material-icons-outlined"><span>fake</span></button>   
        <button onClick={this.addMode} className="material-icons-outlined colorB"><span>add</span></button>
        <button className="material-icons-outlined colorB" onClick={() => this.setMode('setlist')} ><span>format_list_numbered</span></button>
        <button className="fake material-icons-outlined"><span>fake</span></button>  
        <button className={[(this.state.mode === 'account' && 'on') || '','material-icons-outlined','colorE'].join(' ')} onClick={() => this.state.mode === 'account' ? this.setMode('browse') : this.setMode('account')} ><span>account_circle</span></button>        
      </nav>
    );
  }
  songSetlistNav = () => {
    return (<nav>
      <button className="material-icons-outlined colorB" onClick={() => this.setMode('edit')}><span>close</span></button>
      <button  className="fake material-icons-outlined col5"><span>fake</span></button>
      <button  className="material-icons-outlined" onClick={this.removeTag}><span>delete</span></button>
    </nav>);
  }
  accountNav = () => {
   
    return (<nav>
      <button className="material-icons-outlined colorB" onClick={() => this.setMode('browse')}><span>close</span></button>
      <button  className="fake material-icons-outlined col3"><span>fake</span></button>
      <button className="material-icons-outlined colorB" ><span>check</span></button> 
      <button  className="fake material-icons-outlined"><span>fake</span></button>
    </nav>);

  }
  editNav = () => {
    return (<nav>
      <button className="material-icons-outlined colorB" onClick={() => this.setMode('play')}><span>close</span></button>
      <button className="material-icons-outlined colorB" onClick={() => this.setMode('songAddSetlist')}><span>playlist_add</span></button>
      <button  className="fake material-icons-outlined"><span>fake</span></button>
      {this.state.viewsong.songtext === this.state.viewsong.origtext && <button  className="material-icons-outlined colorB" onClick={() => this.setMode('crop')}><span>crop</span></button>}
      {this.state.viewsong.songtext !== this.state.viewsong.origtext && <button className="material-icons-outlined colorB" onClick={() => this.restore()}><span>restore</span></button>}
      <button className="material-icons-outlined" onClick={() => this.delete()}><span>delete</span></button>
      <button  className="fake material-icons-outlined"><span>fake</span></button>
      <button className="material-icons-outlined colorB" onClick={() => {this.updateViewSong(false,this.state.phantomText);this.setState({phantomText:''});this.setMode('play');}}><span>check</span></button> 
    </nav>);
  }
  confirmNav = (inp) => {
    return (<nav>
      <button className="material-icons-outlined colorB" onClick={() => {this.setMode('play'); this.setState({phantomText:''})}}><span>close</span></button>
      <button  className="fake material-icons-outlined col5"><span>fake</span></button>
      <button className="material-icons-outlined colorB" onClick={(ev) => {inp?.();this.setMode('play');this.setState({phantomText:''});}}><span>check</span></button> 
    </nav>);
  }
  editSetlistNav = () => {
    return (<nav>
      <button className="material-icons-outlined colorB" onClick={() => this.setMode('browse')}><span>close</span></button>
      <button  className="fake material-icons-outlined col5"><span>fake</span></button>
      <button className="material-icons-outlined colorB" onClick={(ev) => {this.addTag(ev);this.setMode('browse');}}><span>check</span></button> 
    </nav>);
  }
  cropNav = () => {
    return (<nav>
      <button className="material-icons-outlined colorB" onClick={() => this.setMode('edit')}><span>close</span></button>
      <button  className="fake material-icons-outlined col5"><span>fake</span></button>
      <button className="material-icons-outlined colorB" onClick={(ev) => true}><span>check</span></button> 
    </nav>);
  }

  addNav = () => {
    return ( <nav>
      <button className="material-icons-outlined colorB" onClick={() => {clearInterval(this.addInt) ;this.setMode('browse');} }><span>close</span></button>
      <button className="material-icons-outlined colorB" onClick={this.paste}><span>paste</span></button>
      <button className="fake material-icons-outlined col4"><span>fake</span></button>
      <button className="material-icons-outlined colorB" onClick={() => {clearInterval(this.addInt);this.add();}}><span>check</span></button> 
    </nav>);
  }
  voicingNav() {
    return(
    <nav>
      <button className="material-icons-outlined colorE" onClick={() => this.setMode('play')}><span>close</span></button>
      <button className="fake material-icons-outlined col3"><span>fake</span></button>
      {1===2  && <button   className="material-icons-outlined colorE" onClick={this.setInstrument}><span>music_note</span></button>  }
      <button onClick={() => this.transpose(-1)} className="material-icons-outlined colorE"><span>remove</span></button>
      <button onClick={() => this.transpose(0)} className="material-icons-outlined colorE"><span>exposure_zero</span></button>
      <button onClick={() => this.transpose(1)} className="material-icons-outlined colorE"><span>add</span></button>
    </nav>);
  }
  setListNav() {
    return (
      <nav>
      <button className="material-icons-outlined colorB" onClick={() => this.setMode('browse')}><span>close</span></button>
      {1===2 && <button   className="material-icons-outlined" ><span>delete</span></button> }
      {1===2 && <button   className="material-icons-outlined colorB" ><span>new_label</span></button>   }
      <button   className="material-icons-outlined colorB" onClick={() => this.setMode('newSetlist')}><span>playlist_add</span></button>    
      <button className="fake material-icons-outlined col3"><span>fake</span></button>
      <button className="material-icons-outlined colorB"><span>keyboard_arrow_up</span></button>
      <button className="material-icons-outlined colorB"><span>keyboard_arrow_down</span></button>
    </nav>
    );
  }
  songNav = () => {
    return(
      <nav>
            <button onClick={this.up} className={['material-icons-outlined colorB browse', this.state.mode === 'browse' ? 'on' : ''].join(' ')} title="songs"><span>folder</span></button> 
            <button className={[(this.state.mode === 'edit' && 'on') || '','material-icons-outlined colorB'].join(' ')} onClick={() => this.state.mode === 'edit' ? this.setMode('play') : this.setMode('edit')} title="edit"><span>edit</span></button>
            <button   className="material-icons-outlined colorE" onClick={() => 1===1 ? this.setMode('voicing') : this.setInstrument()}><span>piano</span></button>   
            <button onClick={this.cycleVoicing} className="material-icons-outlined colorD" title="view"><span>music_note</span></button>
            {1===2 && <button className="material-icons-outlined colorD notmobile" onClick={() => this.toggleFullScreen()}><span>fullscreen</span></button>}
            <button onClick={this.cycleLyrics} className="material-icons-outlined colorD" title="view"><span>chat_bubble_outline</span></button>
            <button onClick={this.prev} className="material-icons-outlined colorB"><span>keyboard_arrow_left</span></button>
            <button onClick={this.next} className="material-icons-outlined colorB"><span>keyboard_arrow_right</span></button>
            {1===2 && <button className={[(this.state.mode === 'source' && 'on') || '', 'material-icons-outlined', 'colorD'].join(' ')} onClick={() => {this.setState({viewOptions : false}); this.state.mode === 'source' ? this.setMode('play') : this.setMode('source')}}><span>source</span></button>}
          </nav>
    );
  }
  scrollMap() {
    // shows a custom scroll bar which jumps to next section
    let viewRatio = this.state.winHeight / this.state.songBookHt;
    let ctrlHt = 60;
    let views = Math.ceil(this.state.songBookHt / this.state.winHeight);
    views = views || 1;
    if (viewRatio >=1) {
      // song fits into screen
      return false;
    }
    return(<div className="winNav" style={{height:ctrlHt}} onClick={this.nextScrollPoint}>
      {Array(views).fill('').map(v => <div className="winNavView" style={{height:ctrlHt/views - 1}}></div>)}
      <div className="winNavViewable" style={{height: ctrlHt * viewRatio, top :  this.state.curScrollY / this.state.songBookHt * ctrlHt }}></div>
    </div>);
  }
  nextScrollPoint = () => {
    // scrolls to the next scroll point for quick move to next stage of song
    // TODO consider overlap in order that the context still visible from before
    var nextScrollY = 0;
    let views = Math.ceil(this.state.songBookHt / this.state.winHeight);
    var scrollPoints = Array(views).fill(0).map((v,idy) => idy * Math.round(this.state.songBookHt / views));
    nextScrollY = scrollPoints.filter(y => y >  this.state.curScrollY)?.[0];
    //console.log('current next scroll',this.state.curScrollY,nextScrollY,'curmax',(this.state.curScrollY + this.state.winHeight),'songBkht', this.state.songBookHt);
    // exceeds the height, then back to top
    if (!nextScrollY || (this.state.curScrollY + this.state.winHeight) >= this.state.songBookHt) {nextScrollY = 0;}
    window.scrollTo({top:nextScrollY,behavior:'smooth'});

  }
  render() {
    return (
        <div className={"single songbook size" + this.state.size} ref={ (divElement) => { this.songBookEl = divElement } }>
          {!this.state.loaded && <div className="welcome">Loading Songs</div>}
          {this.state.mode === 'play' && this.scrollMap()}
          {urlRoute().search_text}
          {this.state?.chordHelp && <div className="chordPeek" >
            {1===2 && song(this.state.viewsong.songtext).voicings(this.state?.chordHelp).join(',')}

            <nav><div className="material-icons-outlined" onClick={() => this.setState({contextPeek : this.state.contextPeek ? false : true})}><span>more_horiz</span></div><div className="material-icons-outlined" onClick={() => this.setMode('addVoicing')}><span>add</span></div><div className="material-icons-outlined" onClick={() => this.setState({chordHelp:false,chordContent:false})}><span>close</span></div></nav>
            
            <div className="focusChord prev">
              {this.state?.chordContext[0] && <div>
              <h2><ChordView chord={this.state?.chordContext[0]} /> <MusicStaff chord={this.state?.chordContext[0]} color={'hsl(272, 64%, 28%)'}/></h2>
              
              <FretMap instrument={instrument()} onSelect={this.setVoicing} selectedVoicing={this.state.viewsong?.options?.voicings?.filter(v => v[0] === this.state.chordContext[0])?.[0]?.[1]} width={100} orientation="svg" chord={this.state?.chordContext[0]} voicing={false} notes={chord(this.state?.chordContext[0]).notes()} intervals={chord(this.state?.chordContext[0]).notes(true)} fretted={instrument().map(chord(this.state?.chordContext[0]).notes())} />
               
              {song(this.state.viewsong.songtext).voicings(this.state?.chordContext[0])?.map( vc => 
                <FretShape voicing={vc}  chord={this.state.chordContext[0]} selected={true} dark={true} width={50}/>)
              }
              
              <FretMap instrument={instrument()} selectedVoicing={this.state.viewsong?.options?.voicings?.filter(v => v[0] === this.state.chordContext[0])?.[0]?.[1]} orientation="textual" onSelect={this.setVoicing} chord={this.state.chordContext[0]} fretted={instrument().map(chord(this.state.chordContext[0]).notes())} /></div>}
            </div>


            <div className="focusChord">
              
              <h2><ChordView chord={this.state?.chordHelp} /> <div className="inlineBlock"><ChordStar chord={this.state.chordHelp} width={40} backgroundColor={'hsl(272, 64%, 28%)'} color={'hsl(273, 65%, 55%)'}/></div><MusicStaff chord={this.state?.chordHelp} color={'hsl(272, 64%, 28%)'} width={800}/></h2>
              <FretMap instrument={instrument()} width={100} onSelect={this.setVoicing} prevVoicing={this.state.viewsong?.options?.voicings?.filter(v => v[0] === this.state.chordContext[0])?.[0]?.[1]} selectedVoicing={this.state.viewsong?.options?.voicings?.filter(v => v[0] === this.state.chordHelp)?.[0]?.[1]}  orientation="svg" chord={this.state?.chordHelp} notes={chord(this.state?.chordHelp).notes()} intervals={chord(this.state?.chordHelp).notes(true)} fretted={instrument().map(chord(this.state?.chordHelp).notes())} />
              <div className="textualFretMap">
              {song(this.state.viewsong.songtext).voicings(this.state?.chordHelp)?.map( vc => 
                <FretShape voicing={vc}  chord={this.state.chordHelp} selected={true} dark={true} width={50}/>)
              }
              </div>
              <FretMap instrument={instrument()} selectedVoicing={this.state.viewsong?.options?.voicings?.filter(v => v[0] === this.state.chordHelp)?.[0]?.[1]} orientation="textual" onSelect={this.setVoicing} chord={this.state.chordHelp} fretted={instrument().map(chord(this.state.chordHelp).notes())} />
            </div>

            <div className="focusChord next">
              
            {this.state?.chordContext[2] && <div>
                <h2><ChordView chord={this.state?.chordContext[2]}/> <MusicStaff chord={this.state?.chordContext[2]} color={'hsl(272, 64%, 28%)'}/></h2>
                <FretMap instrument={instrument()} onSelect={this.setVoicing} width={100} prevVoicing={this.state.viewsong?.options?.voicings?.filter(v => v[0] === this.state.chordHelp)?.[0]?.[1]} selectedVoicing={this.state.viewsong?.options?.voicings?.filter(v => v[0] === this.state.chordContext[2])?.[0]?.[1]} orientation="svg" chord={this.state?.chordContext[2]} notes={chord(this.state?.chordContext[2]).notes()} intervals={chord(this.state?.chordContext[2]).notes(true)} fretted={instrument().map(chord(this.state?.chordContext[2]).notes())} />
                {song(this.state.viewsong.songtext).voicings(this.state?.chordContext[2])?.map( vc => 
                  <FretShape voicing={vc}  chord={this.state.chordContext[2]} selected={true} dark={true} width={50}/>)
                }
                <FretMap instrument={instrument()} selectedVoicing={this.state.viewsong?.options?.voicings?.filter(v => v[0] === this.state.chordContext[2])?.[0]?.[1]} orientation="textual" onSelect={this.setVoicing} chord={this.state.chordContext[2]} fretted={instrument().map(chord(this.state.chordContext[2]).notes())} />
                </div>}

            </div>
            </div>}
            
          <div className="songControls">
            
          <div onClick={() => this.timedReadout('buttonhelp')}> 
          {this.readoutDisplay()}
          </div> 
          <div >
            {this.state.mode === 'add' && this.addNav()}
            {this.state.mode === 'addVoicing' && this.confirmNav(() => this.customVoicing() )}
            {this.state.mode === 'account' && this.accountNav()}
            {this.state.mode === 'crop' && this.cropNav()} 
            {this.state.mode === 'edit' && this.editNav()} 
            {this.state.mode === 'voicing' && this.voicingNav()} 
            {this.state.mode === 'setlist' && this.setListNav()}
            {this.state.mode === 'browse' && this.browseNav()}
            {this.state.mode === 'search' && this.browseNav()}
            {this.state.mode === 'play' && this.songNav()}
            {this.state.mode === 'newSetlist' && this.editSetlistNav()}
            {this.state.mode === 'songAddSetlist' && this.songSetlistNav()}
          </div> 
          </div>
          
          {this.state.mode === 'account' && 


          <div className="account">
            <form>
            <h2>User</h2>
            First Name
            <input type="text" />
            Last Name
            <input type="text" />
            Email 
            <input type="text" />
            Password

            <button>Change Password</button>

            <h2>Subscription</h2>
            Billing Name
            Monthly/Yearly/None (Cancel)
            Renews 
            <button>Modify</button>
            </form>
          </div>


          }


          {this.state.mode === 'add' && 
          <div className="addForm">
            {!song(this.state.allText).valid() && <div>
              &nbsp;
            <div className="tapeLabel"><span className="material-icons-outlined">paste</span>Clipboard song format not recognized.<br />Recopy or edit below then press check to import.</div>
            <textarea placeholder="Paste song text here" onChange={(ev) => this.setState({allText:ev.target.value})} value={this.state.allText}></textarea></div>}
          </div>
          }
          {this.state.mode === 'crop' && 
          <div className="cropLines">
            {this.state.viewsong.songtext.split(/\n/).map((l,lineNo) => <a href="#" className={this.lineCss(l)}><span onClick={() => this.cropRange(lineNo,false)} className="material-icons-outlined">first_page</span>{l}<span className="material-icons-outlined" onClick={() => this.cropRange(false,lineNo)}>last_page</span></a>)}
          </div>}

          {this.state.mode === 'edit' && 
          <div className="editForm">
            {1===2 && <div className="usermsg">Edit song</div>}

            {1===2 && <ul >{this.state.preferences.tags.map(item => 
              <li className={this.state.viewsong.options?.tags?.indexOf(item) > -1 ? 'on' : ''} onClick={() => this.state.viewsong.options?.tags?.indexOf(item) === -1  ? this.assignTag(this.state.viewsong.id,item) : this.removeTagFromSong(item)}><div className="tapeLabel">/{item}</div></li>)}</ul>}

            <PhantomEditor value={song(this.state.viewsong.songtext).songPart(true).join('\n')} placeholder="Paste song text here" onChange={(nv) => this.setState({phantomText : nv})} />
            {1===2 && <div className="phantomEditor" >{song(this.state.viewsong.songtext).songPart(true).map((line,lineNo) => <div key={lineNo}>{line.split('').map((chr,chIdx) => <b key={chIdx} onClick={() => this.phantomCursor(lineNo,chIdx)}>{chr !== ' ' ? chr : <span>&nbsp;</span>}</b>)}</div>)}</div>}
            {1===2 && <textarea placeholder="Paste song text here" onChange={(ev) => {var vs = this.state.viewsong; vs.songtext = ev.target.value; this.setState({viewsong : vs});}} value={song(this.state.viewsong.songtext).songPart(true).join('\n')}></textarea>}
          </div>} 
          
          {(this.state.mode === 'newSetlist' || this.state.mode === 'addVoicing') &&  <div>
            
            <input type="text" autoFocus={true} className="phantomText" onBlur={(ev) => {ev.preventDefault();  ev.currentTarget.focus();}} value={this.state.phantomText || ''} onChange={(ev) => this.setState({phantomText: ev.target.value,phantomSelection:[ev.target.selectionStart,ev.target.selectionEnd]}) } onKeyUp={(ev) => {ev.preventDefault();this.setState({phantomSelection:[ev.target.selectionStart,ev.target.selectionEnd]})} }/>
          </div>}

          {(this.state.mode === 'setlist' || this.state.mode === 'songAddSetlist') &&

          <div className="labelSet">
            {this.state.preferences.tags.map(item => 
              <div className={(this.state.selectedTag === item && 'on') || ''}>
              <div className="tapeLabel" onClick={() => this.setState({selectedTag : this.state.selectedTag === item ? false : item})}>{item}</div>
              <div className="setSongs">
              {this.state.songlist.filter(song => song.options.tags.filter(tag => tag === item).length > 0).map((thisSong,idx) => <div className={thisSong.id === this.state.viewsong.id ? 'on' : ''} onClick={() => { this.setMode('songAddSetlist'); this.setState({viewsong : thisSong,selectedTag : item})  }}>{idx+1}. {song(thisSong.songtext).title().replace(/( chords)* by .+/,'')}</div>)}
              </div>
              
              </div>)}
              {Object.entries(this.state.preferences.setlistNames).map(([k,v]) => <div className="tapeLabel">{v}</div>)}  
              {1===2 && <div className="tapeLabel"><span></span>/</div>}
          </div>}

          {(this.state.mode === 'browse'  || this.state.mode === 'search') && <input type="text" autoFocus={true} className="phantomText" onBlur={(ev) => {ev.preventDefault(); 1===2 && ev.currentTarget.focus();}} value={this.state.phantomText || ''} onChange={(ev) => this.setState({phantomText: ev.target.value,phantomSelection:[ev.target.selectionStart,ev.target.selectionEnd]}) } onKeyUp={(ev) => {ev.preventDefault();this.setState({phantomSelection:[ev.target.selectionStart,ev.target.selectionEnd]})} }/>}

          {(this.state.mode === 'browse'  || this.state.mode === 'setlist' || this.state.mode === 'search') && <div className="browseView">           
          <table className="songList grid">
            <thead>
              <tr>
                <th>Song&nbsp;&nbsp;&nbsp;&nbsp;Artist</th><th>Key</th><th>Progressions</th><th>Structure</th>
              </tr>
            </thead>

            <tbody>
              
            {this.sortedsongs().map((item,idx) => 
            <tr onClick={(ev) => {ev.preventDefault();this.songSelect(item.ref);}}>
              <td>
              <div className="songtitlesm">{item.title}
              <a href={'#' + item.url}></a>
              </div>
              <div>{item.artist}</div>
              </td>
              <td>
              {item.key}<br />
                {1===2 && <div>+0</div>}
                {item.capo && <div>CAPO&nbsp;{item.capo}</div> }
                </td>
              <td>
              <div>{item.progression?.map(prog => <div>{prog}</div>)}</div>
              </td>
              <td>{item.verseChorusBridge}</td>
            </tr>)}
            </tbody></table>
          </div>}



          {this.state.mode !== 'browse' && 
          <div className={['detail',this.state?.chordHelp && 'peekPad'].join(' ')}>

            {(this.state.mode === 'source' ||  this.state.mode === 'debug') && 
                <div className="songtitle">{song(this.state.viewsong.songtext).title()}</div>
            }
            {this.state.mode === 'raw' && <pre>{this.state.viewsong.songtext}</pre>}
            {this.state.mode === 'source' && <pre>{song(this.state.viewsong.songtext).songPart().join('\n')}</pre>}
            {(this.state.mode === 'play' || this.state.mode === 'debug') && <SongViewMobile onChord={this.chordHelp} song={this.state.viewsong}  songObj={song(this.state.viewsong.songtext)} detailLyric={this.state.preferences?.detailLyric || this.detailLevels()[0]} detailVoicing={this.state.preferences?.detailVoicing || this.detailLevels()[0]} debug={this.state.mode === 'debug'}/>}

            {this.state.mode === 'voicing' && <div className="voicing">              
              {<div className="voicings">
                
                  <div>
                    Key of {song(this.state.viewsong.songtext).key()}
                    <MusicStaff chord={chord('').sharpsFlatsKey(song(this.state.viewsong.songtext).key())} color={'hsl(272, 64%, 28%)'}/>
                    
                  </div><br />

                  {1===2 && <div>

                  {song(this.state.viewsong.songtext).chords().map(c => chord(c).transpose(this.state.viewsong.options && this.state.viewsong.options.transpose)).map(c => {return <div> 
                    <h2>{c}</h2>
                    <MusicStaff chord={c} color={'hsl(272, 64%, 28%)'}/>
                  {(!this.state.preferences.instrument || this.state.preferences.instrument === 'guitar') && <div className="instrumentpane">
                    <FretMap instrument={instrument()} width={100} orientation="svg" chord={c} notes={chord(c).notes()} intervals={chord(c).notes(true)} fretted={instrument().map(chord(c).notes())} />
                  </div>}
                  {this.state.preferences.instrument === 'trumpet' && <div className="instrumentpane">
                    {trumpet().map(chord(c).notes()).map(item => <HornMap fingers={item} buttons={3}/>)}
                  </div>}
                  {this.state.preferences.instrument === 'piano' && <div className="instrumentpane">
                    <PianoMap notes={chord(c).notes()} size="tiny" intervals={chord(c).notes(true)}  />
                  </div>}
                  
                  <br /><br />

                  <ChordStar chord={c} width={90} backgroundColor={'hsl(272, 64%, 28%)'} color={'hsl(272, 64%, 54%)'} songKey={song(this.state.viewsong.songtext).key().join('')}/>  
                  </div>})}
                  </div>}


                  </div>}
                  
                  

                  <div className="songkey txtC">
                  <h3 >Repeating Chord Progression</h3>
                  <div className="big">
                  {song(this.state.viewsong.songtext).progressionVI().map(prog => <div>{song(this.state.viewsong.songtext).progression2Numerals(prog)} <br />{prog}</div>)}
                  </div></div>

              </div>}


            {1===2 && this.state.mode === 'voicing'  && <section className="expand songkey">
                  <div className="sectiontitle">method 1</div>
                  <div className="big">{song(this.state.viewsong.songtext).progressionV().map(prog => <div>{prog}</div>)}</div>
                  <div className="sectiontitle">method 2</div><ul className="big">
                  {song(this.state.viewsong.songtext).chordBunch().filter(cB => cB.split(/\s+/g)?.length>2).map((item,idx) => <li key={idx}><span>{idx}:</span> {item}</li>)}
                  </ul>
            </section>}


            {1===2 && this.state.mode === 'voicing' && <section className="expand songshapes">
                <h3><a href="#selinstrument">{this.state.preferences.instrument}</a> Shapes</h3>
                <div className="fretpane">
                <ChordParts chord={this.state.chordSearch} onChange={(chord) => this.setState({ chordSearch : chord})}/>
                </div>         
            </section>}
            
            
            {this.state.mode === 'print' && <div>
        
              <div className="printPages">
                  {this.state.songlist.map(song => <SongView song={song}  />)}
               </div> 
              
              </div>}
          </div>} 
        </div>
    );
  }
}

class ChordParts extends React.Component {
  render() {
    return (<table className="chordParts">
    <tbody>
      <tr>
        <td rowSpan={2} className="long">
        <input type="text" onChange={(ev) => this.props?.onChange(ev.target.value)} value={this.props.chord} placeholder="Chord" size={8}/>
          
        </td>
        <td className="long">Interval</td>
        {chord(this.props.chord).notes(true).map(rel => <td className={[rel + 'Note','noteColor'].join(' ')}>{chord().intrvlToLong(rel)?.split(/\s/g)?.map(item => <div>{item}</div>)}</td>)}
      </tr>
      <tr>
        <td className="long">Note</td>
        {chord(this.props.chord).notes().map((note,idx) => <td className={[chord(this.props.chord).notes(true)[idx] + 'Note','noteColor'].join(' ')}>{note.toUpperCase()}</td>)}
      </tr>
    </tbody>
  </table>);
  }
}  

class ChordView extends React.Component {
  render() {
    return(<span  onClick={(ev) => this.props.onHelp(this.chord() || false, {left:ev.pageX,top:ev.pageY})} className="chordHelp">
      <span className="repeatNotation">{this.repeatNotation()}</span>
      <span className="chordLetter">{this.chordLetter()}</span><span className="chordSharpFlat">{this.chordSharpFlat()}</span>
      <span className="chordNonParse">{this.chordNonParse()}</span><span className="chordMajMin">{this.chordMajMin()}</span>
      <span className="chord7th">{this.chord7th()}</span>
      <span className="chordBass">{this.chordBass()}</span> 
      <span className="repeatNotation">{this.repeatNotation(1)}</span>
      <span className="chordMultiplier">{this.chordMultiplier()}</span> {1===2 && <span className="chordOrig">{this.props.chord}</span>}</span>);
  }
  chordBass() {
    return this.chord().match(/\/[ABCDEFG][b#]?$/);
  }
  chord7th() {
    return this.chord().replace(/x\d+/i,'').match(/(7)/g);
  }
  repeatNotation(end) {
    
    if (end &&  this.props.chord.trim().match(/\]+/)  ) return this.props.chord.trim().match(/\]+/);
    return (!end && this.props.chord.trim().match(/\[+/)  ) ? this.props.chord.trim().match(/\[+/) : '';
  }
  chordNonParse() {
    return this.chord().replace(/\/[ABCDEFG](b|#)?$/,'').replace(/x\d+/,'').replace(/(7)/,'').replace(/(^[ABCDEFGabcdefg])(#|b)/,'$1').replace(/(sus\d*|maj|aug|dim|\(?add\d+\)?)/i,'').replace(/x\d+$/,'').substr(1);
  }
  chordLetter() {
    return this.chord()[0]?.replace(/[\[\]]/g,'');
  }
  chordMajMin() {
    return this.chord().match(/(sus\d*|maj|aug|dim|add\d+)/gi);
  }
  chordSharpFlat() {
    return this.chord().match(/^[ABCDEFGabcdefg](#|b)/)?.[0][1];
  }
  chordMultiplier() {
    return this.props.chord.match(/x\d+$/i);
  }
  chord() {
    // transpose the text if needed
    if (this.props.transpose) {
      var l = ''; var r = '';
      if (this.props.chord[0] === '[') {
        l = '[';
      }
      r = this.props.chord.trim().match(/\]x\d+$/)?.[0] || '';
       
      //return [l,chord(this.props.chord.replace(/\]x\d+$/g,'').replace(/[\[\]]/g,'')).transpose(this.props.transpose),r].join('');
      return chord(this.props.chord.replace(/x\d+$/g,'').replace(/[\[\]]/g,'')).transpose(this.props.transpose);
    } else {
      return this.props.chord.replace(/[\[\]]/g,'').replace(/x\d+$/g,'');
    }  
  }
}

class SongView extends React.Component {
  constructor(props) {
    super(props);
    this.songCache = false;
    this.songtextCache = '';
  }
  longestWord(phrase) {
    let words = phrase.split(/\s+/);
    return words.map(w => [w,w.length]).sort((a,b) => b[1] - a[1])[0][0];
  }
  detailedLyric(lyric,idx) {
     
    if (this.props.detailLyric === 'less') {
      lyric = lyric.trim();
      if (!lyric?.length) {
        return false;
      } else if  (lyric.split(/\s+/).length > 4) {
        
        return <span>{lyric.split(/\s+/)[0]} {lyric.split(/\s+/)[1]} {lyric.split(/\s+/)[2]} {('.').repeat(lyric.split(/\s+/).length-3)}</span>;
      } else {
        return <span>{lyric}</span>;
      }
    } else if (this.props.detailLyric === 'full') {
      return <div>{lyric}</div>;
    } else if (this.props.detailLyric === 'none') {
      return <span></span>
    } else if (this.props.detailLyric === 'more') {
      // only the longest word, this is really LESS but wanted to reduce complexity today
      return <span>{this.longestWord(lyric)}...</span>
    } else {
      return <span>{lyric}</span>;
    }
      
  }
  chordHelp = (chord, prev,next) => {
    
    if (chord) {
      this.props.onChord?.(chord,prev || false,next || false);
      //this.setState({chordHelp : chord, helpTarget: pos})
    } else {
      this.props.onChord?.(false);
    }
  }
  chunkClasses(idx) {
    return ['chunkA','chunkB','chunkC','chunkD','chunkE','chunkF','chunkG','chunkH'][idx];
  }
  titleCss(chunkLabel) {
    var out =[];
    if (chunkLabel.match(/\bchorus\b/i)) {
      out.push('chorus');
    }
    if (chunkLabel.match(/\bverse\b/i)) {
      out.push('verse');
    }
    if (chunkLabel.match(/\bintro\b/i)) {
      out.push('intro');
    }
    if (chunkLabel.match(/\boutro\b/i)) {
      out.push('outro');
    }
    if (chunkLabel.match(/\bbridge\b/i)) {
      out.push('bridge');
    }
    if (chunkLabel.match(/\bpre-?chorus\b/i)) {
      out.push('prechorus');
    }
    if (chunkLabel.match(/\binterlude\b/i)) {
      out.push('interlude');
    }
    return out.join(' ');
  }
  controlLabel = (inp) => {
    
    var out = this.titleCss(inp);
    if (out.length) {
      return out.toUpperCase()[0];
    } else {
      return '';
    }
    
  }
  chunkLabels() {  
    var out = this.chordFactor().map(chunk => chunk.label);
    return out;
  }
  chunkCss(chunkLabel, chunkType) {
    // finds a shared index for song parts of that progression
    // until an index key for the chunkClasses named css classes
    // add verse/chorus/bridge assignments which desires chunkType from chunkLabel not from input
    // need chunkType to be more specific 
    if (!chunkLabel || !chunkType) {return '';}
    var index = -1;

    if (chunkType === 'chord') {
      index = this.chunkLabels().indexOf(chunkLabel);
      //console.log('chord class',chunkLabel,index, this.chunkLabels()); 
    } else if (chunkType === 'lyric') {
      // get the digit and match with labels digit to find index because lyrics arent grouped
      let dig = chunkLabel.match(/\d+/)[0];
      index = this.chunkLabels().map((item,idx) => item.replace(/\[[^\]]+\]/g,'').replace(/[^0-9,]/g,'').split(/,/g).indexOf(dig) !== -1 ? idx : false).filter(i => i !== false)[0];
      //console.log('lyric class',chunkLabel,'dig', dig, index, this.chunkLabels()); 
    } else {
      // not a displayed chunk type 

    }

    if (index!==0 && !index) {
      return 'unfound chunk';
    } else {
      return this.chunkClasses(index) + ' chunk';
    }
  }

  componentDidUpdate(prevProps) {
    console.log('SongViewMobileUpdate',prevProps);
    if (this.props.song.songtext !== prevProps.song.songtext ) {
      console.log('song changed');
       
      //this.setState({verseChorusBridge:song(this.props.song.songtext).verseChorusBridge()});
      /*let sng = song(this.props.song.songtext);
      this.songCache = sng
      this.songtextCache = this.props.song.songtext;
      this.chordFactorCache = sng.chordFactor();
      this.setState({songCache:sng});*/
    }
    
  }
  song() {
    
    if (! this.songCache || this.props.song.songtext !== this.songtextCache) {
      this.songCache = song(this.props.song.songtext);
      this.songtextCache = this.props.song.songtext;
      this.chordFactorCache = this.songCache.chordFactor();
    }
    return this.props.songObj;//song(this.props.song.songtext);
    
   //return this.state?.songCache || song(this.props.song.songtext);
    // this was cache giving non-render issues with caching on the song object model
    //return this.songCache;
  }
  chordFactor() {
   return this.chordFactorCache;
  }

  firstIndex(inp) {
    // this method gets the index of this first lyric chunk, only called in showOnceChords
    let vrsChunks = this.props.songObj.verseChunks();
    return this.props.songObj.verseChorusBridge().reduce((acc,item2,idx2) =>  acc ===-1 && inp === this.chunkCss(vrsChunks[idx2]?.label,'lyric')  ? idx2 : acc,-1 );  
  }
  
  showOnceChords(inp,inpIdx) {
      // display for reduced/grouped chord sets
      // try to keep the chord link breaks when useful from song
      return(this.chordFactor().map((chunk,idx) =>  
        // if labels match this first time
        {return this.chunkCss(chunk.label,'chord') === inp  && inpIdx === this.firstIndex(inp) && <div className="chordfactorpane">
            <div>{this.song().groupChords(     chunk.lines.map(l => this.song().lineChords(l).join(' ')).join(' ')    ).split(/\s+/).filter(item => item.length).map((c,idx,arr) => 
            <React.Fragment>
              { c.match(/[\[]/) && idx>0 && <br />} 
              <ChordView chord={c} transpose={this.props.song.options && this.props.song.options.transpose} onHelp={(v) => this.chordHelp(v,arr[idx-1],arr[idx+1])}/>
              {1===2 && <div class="tiny">{chord(c).valid() ? '':'invalid'}</div>}
              {((!idx%5 && idx>0) || c.match(/[\]]/)) && <span></span>}
            </React.Fragment>
            )}
            </div>
        </div>}
    ));
  }
  nextChord(line,idx,chnk,lineNo) {
    // TODO these don't work across different chunks
    if (!line[idx+1]) {
      // grab the last line that is a chord line and get the first chord from there
      var nextLine = chnk.filter((v,num) => num > lineNo &&  v[1] === 'chord')?.[0]?.[0];
      if (nextLine) {
        var out = nextLine.match(/\S+\s*/g)?.[0];
        return out;
      } else {
        return false;
      }
    } else {
      return line[idx+1];
    }
  }
  prevChord(line,idx,chnk,lineNo) {
    if (!line[idx-1]) {
      // grab the last line that is a chord line and get the final chord from there
      var prevLine = chnk.filter((v,num) => num < lineNo &&  v[1] === 'chord')?.pop()?.[0];
      if (prevLine) {
        var out = prevLine.match(/\S+\s*/g)?.pop();
        return out;
      } else {
        return false;
      }
    } else {
      return line[idx-1];
    }
  }
  render () { 
    
    return(<div className="songView debug">
        <div style={{position:'relative'}}>
        {this.props.detailLyric !== 'none' && this.song().verseChunks().map((chunk,idx) =>  
        <div key={idx} style={{position:'absolute',marginLeft:idx * 180,width:360,top:idx%2 ? 240 : 0}} className={['control' + chunk.verseChorusBridge.toLowerCase().replace(/[^a-z]/gi,''),'verse' + this.props.detailLyric].join(' ')}>
          <div className={['sectiontitle',this.chunkCss(chunk.label,'lyric'), this.titleCss(chunk.label)].join(' ')}><span className="sectionLabel">{(idx+1) < 10 ? '0' + ( idx + 1 ): idx+1} {this.controlLabel(chunk.verseChorusBridge)}</span><span style={{display:'none'}}>{chunk.label}</span></div>
          <ul className="verse">{chunk.lines.filter(l => l[1] === 'lyric').map((l,idx) => {let dL = this.detailedLyric(l[0],idx);return dL.length > 0 && <li>{dL}</li>;} )}</ul>
        </div>
       )}
      </div>

       </div>);
}
}

class SongViewMobile extends SongView {
  
  render () { 
    console.log('render');
    return(<div className={['songView','debug','chord' + this.props.detailVoicing].join(' ')}>
      
      {this.state && this.state.chordHelp && <div className="chordHint" style={{top:this.state.helpTarget.top,left:this.state.helpTarget.left + 24}}>
        {this.state.chordHelp}
        <FretMap instrument={instrument()} fretted={instrument().map(chord(this.state.chordHelp).notes())} />
        </div>}

        {1===2 && this.props.detailVoicing === 'full' && <div className="voicings notmobile">
        {this.song().chords().map(c => {return <div> {c}<br />
        {(!this.props.instrument || this.props.instrument === 'guitar') && <div className="instrumentpane">
          <FretMap instrument={instrument()} orientation="vertical" size="tiny" notes={chord(c).notes()} intervals={chord(c).notes(true)} fretted={instrument().map(chord(c).notes())} />
        </div>}
        {this.props.instrument === 'trumpet' && <div className="instrumentpane">
          {trumpet().map(chord(c).notes()).map(item => <HornMap fingers={item} buttons={3}/>)}
        </div>}
        {this.props.instrument === 'piano' && <div className="instrumentpane">
          <PianoMap notes={chord(c).notes()} size="tiny" intervals={chord(c).notes(true)}  />
        </div>}
        </div>})}
        </div>}
          
          <table className={['lyricList', 'chord' + this.props.detailVoicing].join(' ')}><tbody>
            
            {this.props.songObj.verseChorusBridge().map((item,idx) => {
              var chunkClassName = this.chunkCss(this.song().verseChunks()[idx]?.label,'lyric'); 
              return <tr key={idx} className={chunkClassName}>
              <td ><div>{(item==="V" ? "" : item)}<span>{ item === "V" && ( (idx+1) < 10 ? '0' + ( idx + 1 ): idx+1)  }</span></div></td>

              <td >
                  {(this.props.detailVoicing === 'more' || this.props.detailVoicing === 'less') && this.showOnceChords(chunkClassName,idx)}
                  <ul className={['verse',this.props.detailLyric,this.props.detailVoicing === 'full' ? 'chordfull' : ''].join(' ')}>
                    {this.song().verseChunks()[idx].lines?.filter(l => l[1] === 'lyric' || l[1] === 'chord').map((l,idx2,chnk) => { 
                      let dL = this.detailedLyric(l[0],idx2); 
                    return (dL || l[1] === 'chord') && <li key={idx2}>
                    {l[1] === 'lyric' && dL} 
                    
                    {l[1] === 'chord' && this.props.detailVoicing === 'full' && <div className={['chordfactorpane full'].join(' ')}>
                      {l[0].match(/^\s+/)?.[0].split('')?.map(m => <span>&nbsp;</span>)}
                      {l[0].match(/\S+\s*/g).map((m,idz,arr) =>  <span><ChordView chord={m.trim()} transpose={this.props.song?.options?.transpose} onHelp={(v) => this.chordHelp(v,this.prevChord(arr,idz,chnk,idx2),this.nextChord(arr,idz,chnk,idx2))}/>{m.replace(/\S/g,'').split('').map(s => <span>&nbsp;</span>)}</span>         )}
                      {1===2 && l[0].replace(/\s{6,}(\S+)\s*$/,' $1').replace(/\S+/g,(m,idx3,orig) => {     return chord(m).transpose(this.props.song.options && this.props.song.options.transpose)      } )}
                    </div>} 
                    </li>})}
                  </ul>
              </td>

            </tr>})}
          </tbody></table>   
          
        
        
        {this.props.debug && <div className="debugshow">
        <table className="grid">
          <tbody>
          {this.song().allLines().map(item => <tr><td>{item[1]}</td><td>{item[0]}</td></tr>)}
          </tbody>
        </table>
            <div>Chord chordProgression {1===2 && this.song().chordProgression().map(item => <span>{item}&nbsp;</span>)}</div>
            <div>Chords {this.song().chords().map(item => <span>{item}&nbsp;</span>)}</div>          
            <div>Chord Order {this.song().chordOrder().map(item => <span>{item}&nbsp;</span>)}</div>

              <div>
                <h1>Chords</h1>
                <pre>{this.song().chordLines()}</pre>
                <h1>Lyrics</h1>
                <pre>{this.song().lyricsLines()}</pre>
              </div> 
        </div>}

       </div>);
}
}

export default App;