import React from 'react';
import axios from 'axios';

function museUser() {
    return {
      firstname : '',
      lastname : '',
      email : '',
      password : '',
      passwordRepeat : '',
      ccfullname : '',
      ccpostal : '',
      creditcard : '',
      expdate : '',
      cvv : '',
      terms : '',
  
      endpointUrl : '',
      validateFields() {
  
      },
      
      processPayment() {
        let url = this.endpointUrl + '&mode=pay';
      },
      register() {
        let url = this.endpointUrl + '&mode=register';
      },
      save() {
        let url = this.endpointUrl + '&mode=save';
      }
    };
  }
class Registration extends React.Component {
    // allow for new user registrations, process payment cycle, correct forms, flow forms 
    constructor(props) {
      super(props);
      this.state = {mUser: museUser(),mode : 'signup'};
    }
    
    payment() {
      return(<div>    
          <form>
          <h2>payment</h2>
          <input type="text" placeholder="cardholder fullname" value={this.state.mUser.ccfullname} onChange={(ev) => this.setUser(ev,'ccfullname')}/>
          <input type="text" placeholder="postal code" value={this.state.mUser.ccpostal} onChange={(ev) => this.setUser(ev,'ccpostal')}/>
          
          <br /><br />
          <input type="text" placeholder="credit card number" value={this.state.mUser.creditcard} onChange={(ev) => this.setUser(ev,'creditcard')}/>
          <input type="text" size="10" placeholder="expires MMYY" value={this.state.mUser.expdate}  onChange={(ev) => this.setUser(ev,'expdate')}/> <input type="text" size="10" placeholder="security code" onChange={(ev) => this.setUser(ev,'cvv')} value={this.state.mUser.cvv}/>
          <div class="termsagree"><input type="checkbox" value={this.state.mUser.terms} onChange={(ev) => this.setUser(ev,'terms')}/> I agree to the terms</div>
          <button class="cta" onClick={() => this.setState({mode : 'complete'})}>Complete Payment</button>
        </form>
  
      </div>);
    }
    setUser = (ev,field) => {
      let mU = this.state.mUser;
      mU[field] = ev.target.value;
      this.setState({mUser : mU});
    }
    signup() {
      return(<div>
        sign up    
        
        <form>
          <h2>try muse</h2>
          <button>Sign Up With EMail</button>
          <button>Google</button>
          <p>
              To create an account provide your email address. You do not have to provide payment method for a demo account.
          </p>
          <input type="text" placeholder="first name" value={this.state.mUser.firstname} onChange={(ev) => this.setUser(ev,'firstname')}/> <input type="text" placeholder="last name" value={this.state.mUser.lastname} onChange={(ev) => this.setUser(ev,'lastname')}/>
          <input type="text" placeholder="email address" value={this.state.mUser.email} onChange={(ev) => this.setUser(ev,'email')}/>
          <input type="password" placeholder="password (min 8 characters)" value={this.state.mUser.password} onChange={(ev) => this.setUser(ev,'password')}/>
          <input type="password" placeholder="repeat password" value={this.state.mUser.passwordRepeat} onChange={(ev) => this.setUser(ev,'passwordRepeat')}/>
          <button class="cta" onClick={() => this.setState({mode : 'payment'})}>Next</button>
          <p> We will not provide your email address to anyone. See Privacy section.</p>
          <br /><br /><br />
         </form>
  
        </div>);
    }
    complete() {
      return(<div>
  
        <form >
          <h2>complete</h2>
          
          <div class="txtC">
          <p>Enter the activation code <br />sent to your email</p>
          <br />
          <input type="text" placeholder="______" class="actCode" maxlength="6" />
          <br /><br /><br />
          <button class="actCode cta" onClick={() => this.setState({mode : 'signup'})}>Try Muse</button>
          <a href="#">i didn't receive email</a>
          </div>
          <br /><br /><br /><br /><br /><br /><br />
          
       </form>
      
  
      </div>);
    }
    render() {
      return(<div className="registration">
  
        {this.state.mode === 'payment' && this.payment()}
        {this.state.mode === 'complete' && this.complete()}
        {this.state.mode === 'signup' && this.signup()}
      </div>);
    }
  
  }  
  export default Registration;