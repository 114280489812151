import chord from './chord';
// verse, chorus, bridge, intro , outro

// 1. songPart (firstline(),lastline())
function song(inTxt) {
    var out = {
      allText : inTxt || '',
      verseChorusCache : '',
      songChunkCache : false,
      format : function() {
        var lines = this.allText.split(/\n+/g);
        return lines.join('**');
      },
      valid: function ()  {
        let out = true;
        if (!this.title()) {
          out = false;
        }
        // need to find more ways to ensure that the clipboard has a valid song, this is very basic to get the interface working 
        return out;
      },
      firstLine : function(allLines) {
        if (!allLines) {
          allLines = this.allText.split(/\n+/g);
        }
        var out = allLines.indexOf(this.title());
        if (out < 0) {
          out = 0;
        }
        return out;

      },

      lastLine : function(allLines) {
        if (!allLines) {
          allLines = this.allText.split(/\n+/g);
        }
        var ends = [allLines.indexOf('By helping UG you make the world better... and earn IQ')];
        ends.push(allLines.indexOf('Please rate this tab'));
        ends.push(allLines.indexOf('Tap to rate this tab'));
        ends = ends.filter(v => v > -1);
        ends.sort();
        return ends[0];
      },

      title : function() {
        var allLines = this.allText.split(/\n+/g);
        var lines = allLines.map((item,idx) => item.match(/\b(by|chords)\b/i) || item.match(/^\s*title\s*:/i) ? idx : -1);
        //if (!lines || !lines.length) {
        //  lines = allLines.map((item,idx) => item.match(/\bchords\b/i) ? idx : -1);
        //}
        lines = lines.filter(v => v > -1);
        lines.sort((a,b) => a-b);
        if (lines.length) {
          var titleIndex = lines[0];
          return allLines[titleIndex];
        } else {
          return "";
        }
      },
      artist : function() {
        var lines = this.allText.split(/\n+/g).map((item,idx) => item.match(/^\s*artist\s*:/i) ? idx : -1);
        lines = lines.filter(v => v > -1);
        lines.sort((a,b) => a-b);
        if (lines.length) {
          var titleIndex = lines[0];
          return this.allText.split(/\n+/g)[titleIndex].replace(/^\s*artist\s*:/i,'');
        } else {
          return this.title().replace(/^.+ by\s+/,'');
        }

        
      },
      titleFormat : function() {
        return this.title().replace(/ chords by .+/,'').replace(/\s*chords\s*/,'').replace(/^\s*title\s*:/i,'');
      },
      chordOrder : function() {
        var lines = this.chordLines();
        if (lines.length) {
          return lines.split(/\s+/);
        } else {
          return [];
        }
      },

      chordCombinations : function() {
        var result = [];
        var chars = this.chords();
        var f = function(prefix, chars) {
          for (var i = 0; i < chars.length; i++) {
            result.push(prefix + chars[i]);
            f(prefix + chars[i], chars.slice(i + 1));
          }
        }
        f('', chars);
        return result;
      },
      chordProgression : function()  {
        // get the barechords and build factors for grouping
        // using every combination of chords in the chart
        var chords = this.chordOrder();
        //console.log(this.chordOrder());
        var combos = [];
        var repeats = [];
  
        var maxLen = 10;//Math.floor(chords.length / 2);
        var minLen = 2;
  
        //get every possible combo in this order
        for(var l = minLen; l<=maxLen; l++){
          for (var i = 0; i+l < chords.length;i++){
            combos.push([i,l,chords.slice(i,i+l),1]);
          }
        }
        for(var a = 0; a < combos.length; a++) {
          for(var b = 0; b < combos.length; b++) {
              // check not overlap and if match, then increment the counter [3] 
              if (combos[a][2].join('') === combos[b][2].join('')) {
                if (combos[a][0] + combos[a][1] < combos[b][0] || combos[a][0] + combos[a][1] > combos[b][0] + combos[b][1]) {
                  combos[a][3]++;
                }  
              }
          }
        }
        // only with more than a single match 
        combos = combos.filter(item => item[3] > 1);
        // collapse all of the same combo 
        var patterns = combos.reduce(   (acc,item) => {       
          var found = acc.filter(a => a[0] === item[2].join(''));      
          if (!found.length) {
              acc.push(  [item[2].join(''),item[3]]);
          }    
          return acc;
         },[]);
         //these repeats need to basic differing unit
         //if repeat of smaller combo, then remove or if nested within larger progression, keep the larger of the two
         patterns = patterns.filter(a => 
           patterns.filter(b => 
            (   (a[0] !== b[0] && a[0].replace(RegExp(b[0],'g'),'') === '') ||   ( a[0] !== b[0] && b[0].indexOf(a[0]) > -1 && a[1] === b[1])   )
           ).length === 0
           
         );
        
        // TODO repeats begin immediately after the combo
        patterns.sort((a,b) => b[1] - a[1] ); 
        //console.log('patterns',patterns);
        //console.log('combos',combos);
        return this.chordCombinations();
      },

      chords : function() {
        //distinct chords
        return this.chordOrder().filter((item,idx) => this.chordOrder().indexOf(item) === idx);
      },
      chordLines : function() {
        // find chordLines like in the example using chord().valid
        var lines = this.allText.split(/\n+/g).slice(this.firstLine(), this.lastLine()).filter(item => this.lineType(item) === 'chord');

        if (lines) {
          return lines.join('\n');
        } else {
          return "";
        }
      },

      songPart : function(preserveNewLines) {
        // step 1, get the part of text where the song is
        if (preserveNewLines === true) {
          var lines = this.allText.split(/\n/g);
        } else {
          var lines = this.allText.split(/\n+/g);
        }
        lines = lines.slice(this.firstLine(lines), this.lastLine(lines));
        if (lines) {
          return lines;
        } else {
          return "";
        }
      },
      controlLabels :function() {
        // find labels in text which helps w spacing
        let out = false;
        var labelPattern = '\\[\s*(' + this.namedSongParts().join('|') + ')[^\\]]*\\]';
        out = this.allText.match(new RegExp(labelPattern,'gi'));
        if (!out) {
          out =[];
        }
        return out;
      },
      songChunks : function() {
        // break up song by line type into verse/chords/etc named or otherwise
        // TODO reduce calls to this heavy method
        if (this.songChunkCache && this.songChunkCache.length) {
          return  this.songChunkCache.filter(v => true);
        }
        var out = [];
        var chunk = {label:'0 Title',lines:[]};
        var allLines = this.allLines();

        var emptyGroups = allLines.map(item => item[1]).join(' ');
        // find if song is mostly double spaced by looking for consecutive empty in the linetype field
        var spaced = [emptyGroups.match(/\bempty\b/g) && emptyGroups.match(/\bempty\b/g).length, emptyGroups.match(/\bempty empty\b/g) && emptyGroups.match(/\bempty empty\b/g).length,emptyGroups.match(/\bempty empty empty\b/g) && emptyGroups.match(/\bempty empty empty\b/g).length];
        // counts for single/double/triple spaced, remove greater counts from each lower total
        spaced[0] =  spaced[0] - spaced[1]*2 -spaced[2]*3;
        spaced[1] = spaced[1] - spaced[2]*3;
        var doubleSp = spaced[1] / (spaced[0] + spaced[1]) > .33;
        var controlLabels = this.controlLabels();
        //console.log('emptygroups',emptyGroups,spaced, doubleSp);
        //console.log('this controlLabels : ',controlLabels?.length);
        // make chuncks
        allLines.forEach((item,idx) => {


          // if this.controlLabels()
          
          if (item[1] === "control" 
          || (controlLabels.length < 3 && doubleSp && idx > 2 && allLines[idx-2][1] === 'empty' && allLines[idx-1][1] === 'empty' && idx < allLines.length -1 && allLines[idx+1][1] !== 'control') 
          || (controlLabels.length < 3 && !doubleSp && idx > 1 && allLines[idx-1][1] === 'empty' && idx < allLines.length -1 && allLines[idx+1][1] !== 'control')     ) {
            // new chunk
            out.push(chunk);
            // labeling song chunks from control text
            chunk = {label:(item[1] === "control" &&  out.length.toString() + ' ' + item[0]) || out.length.toString(),lines:[item]};
          } else {
            chunk.lines.push(item);
          }
        });
        out.push(chunk);
        // refactor chunks for missing labels 
        
        // find label to describe content (defaults to verse otherwise)
        out.forEach(chunk => {
          var hold = this.findSongPartLabel(chunk.label);
          var test = "";
          if (!hold) {
            // try first line of the content
            test = chunk.lines.map(l => l[0]).join(' ');
            hold = this.findSongPartLabel(test);
          }
          if (!hold) {
            // test the content against other chunks
            if (out.filter( ch => ch.lines.map(l => l[0]).join(' ') === test).length > 1) {
              hold = 'chorus';
            }
            //hold = this.findSongPartContent(test);
          }
          //console.log('chunk label',chunk.label,'hold', hold,'sup',test);
          chunk.verseChorusBridge = hold || "";
          return chunk;
        });
        if (out) this.songChunkCache = out.filter(v => true);
        //console.log('pre cached',out);
        return out;
      },

      lyricsLines : function() {
        var lyrics = this.songPart();
        if (lyrics) {
          lyrics = lyrics.filter(l => this.lineType(l) === 'lyric' || this.lineType(l) === 'empty');
          return lyrics.join('\n');
        } else {
          return "";
        }
      },

      allLines() {
        var lines = this.songPart();
        return lines.map(item => [item,this.lineType(item)]);
      },
      addVoicing(chd,vcText) {
        // append below last voicing 
        
        let songL = this.allText.split(/\n/g)
        var lastVoicingLine = songL.map((l,idx) => (this.lineType(l) === 'voicing' && idx) || -1).reduce((acc,lineNo) => lineNo > acc ? lineNo : acc,-1);
        let lineNo = lastVoicingLine+1;
        if (!lineNo || lineNo < 1 || lineNo>=songL.length) {
          // add before the final lines
          lineNo = this.firstLine(songL);
          lineNo++;
        }
        console.log('splice line:',lineNo);
        // splice in the voicing
        songL.splice(lineNo,0,[chd,vcText].join(' '));
        return songL.join('\n');
      },
      voicings(chd) {
        var out = this.allLines().filter(l => l[1] === 'voicing').map(l => l[0]);
        if (chd) {
          out = out.filter(l => l.indexOf(chd + ' ') === 0 || l.indexOf(' ' + chd + ' ') > -1).map(l => l.match(/((\d+?|x)( |-)?){6}/i)[0]);
          out = out.map( l =>    ( l.indexOf('-') > -1 && l.split('-') )   ||     ( l.indexOf(' ') > -1 && l.split(/\s+/) )   ||    l.split('')       );
        }
        return out;
      },
      firstChordParagraph() {
        var out = this.chordChunks()[0] ? this.chordChunks()[0].label.match(/^[0-9]+/) : -1;
        if (out !== -1) out = out[0];
        return out;
      },

      noteIntervals(notes) {
         var out = notes.map((item,idx)=>
            item && idx < notes.length-1 && chord().noteInterval(item, notes[idx+1])
         );
        return out;
      },
      lineChords(line) {
        // format text line of chords into clean array
        return line[0].replace(/^|\s+|$/g,' ').replace(/(\s*\w+\s)\1+/gi,'$1').trim().split(/\s+/);
      },

      groupChords(chords){
        if (!chords) {
          chords ='';
          //this.chordFactor()
          
          chords = this.chordFactor().map(   (chunk,idx) =>  chunk.lines.map(l => this.lineChords(l).join(' ')).join(' ') ).join(' ')   ;

        }
        // try this with just regular expression modeled after
        // "C G C Bm Am G C G C Bm Am G F D F D A ".replace(/((\w+\s)+?)\1+/g,'[$1] ')
        // better version of above (nesting and using non-space instead of w spec char)
        // "C G C G Bm Am C G C G Bm Am G F D F D A ".replace(/((\S+\s)+?)\1+/g,'[$1] ').replace(/((\S+\s)+?)\1+/g,'[$1] ')
        // keep compressing until no change
        var lastRtn = chords + ' ';
        var rtn = chords;
        while(rtn.length < lastRtn.length) {
          lastRtn = rtn;
          // regex explain 0. add trail sp. 1. find repeat non-spaces chords 2. if the same chord itself is repeated over and over, then dont condense and return entire 3. else return repeating part with repeat count if > 2
          rtn = (lastRtn + ' ').replace(/((\S+\s)+?)\1+/g,this.regexChordGroup );
        }
        return rtn;
      },

      regexChordGroup(match,p1,p2,offset,string) {
        // regex explain 0. add trail sp. 1. find repeat non-spaces chords 2. if the same chord itself is repeated over and over, then dont condense and return entire 3. else return repeating part with repeat count if > 2
        var out = match.split(/\s+/).filter((v,idx) => v.length && match.split(/\s+/).indexOf(v) === idx ).length < 2 ? p1.trim() + ' ' : '[' + p1.trim() + ']' + (match.length/p1.length > 2 ? 'x' + match.length/p1.length + ' ' : ' ');
       return  out;
      },
      capo() {
        // control lines from songText with the word Capo
        let capoLines = this.allLines().filter(item => item[1] === 'control' && item[0].toLowerCase().indexOf('capo') !== -1);
        if (capoLines.length) {
            // get the number right after word capo
            let capoFrets = capoLines.map(item => item[0].match(/Capo[^0-9]+[0-9]{1,2}/)).map(item => item && item[0].replace(/[^0-9]/g,''));
            return capoFrets[0]; // perhaps later check for agreenent, but the first occurance is prob safe bet
        } else {
            return ''; // no capo, perhaps rtn 0 or false
        }
      },
      fitKey(prg) {
        // numeric sequence of the chord progression based on the key of the song
        var keyProgs = this.key().map(k =>  chord(k).buildProgession());
        var chords = prg.split(/\s+/g);
        var byKey = keyProgs.map(kp => chords.map (chd => kp.indexOf(  chd.match(/[A-G](#|b)?m?\d?/)?.[0]?.toLowerCase())) );
        // sort by most fits
        byKey.sort((a,b) => a.reduce((tot,v) => v === -1 ? tot + 1 : tot,0) - b.reduce((tot,v) => v === -1 ? tot + 1 : tot,0) );
        //console.log('byKey',byKey);
        return byKey[0];
        
      },
      progressionVI() {
        // generate a progression based on text grouping, condensation, and line breaks
        // remove bass note to simplify
        var out = this.groupChords().split(/\s+/).map(c => c.replace(/\/[A-G](#|b)?/,'')).join(' ');
        //condense chords
        out = out.replace(/(\S+\s)\1+/g,'$1');
        // break off repeats, remove repeat symbols and condense again
        var repeats = out.match(/\[+[^\]]+\]+/g)?.map(v => (v.replace(/[\[\]]/g,'') + ' ').replace(/(\S+\s)\1+/g,'$1').trim()       );
        
        // TODO add long points between repeats from single chunk
        var nonRepeats = out.split(/\[+[^\]]+\]+/).sort((a,b) => a.length - b.length);
        //out = out.replace(/\[+[^\]]+\]+/g,' ');
        //console.log('progVI repeats', repeats,nonRepeats);
       //return out;
       
        return repeats ? repeats.filter((v,idx) => v.split(/\s+/)?.length>2 && v.split(/\s+/)?.length<8 && repeats.indexOf(v) === idx) : [];
      },
      progression2Numerals(prog) {

        var out = this.fitKey(prog);
        out = out.map(chd => chord().numeral()[chd] || chd).join(' ');
        return out;
      },
      progressionV() {
        // REM soon, this method has been replace w progressionVI()

          // get universal sequence of repeating progressions
        let repeatProg = this.chordBunch();
        // match chords to sequence in key mode
        var out = repeatProg.map(prg => this.fitKey(prg));
        //console.log('progressionV out 1',out);
        // convert to numbers
        out = out.map(prg => prg.map(chd => chord().numeral()[chd] || chd).join(' ')).filter(prog => prog.split(/\s+/g).length > 2);
        //console.log('progressionV out 2',out);
        return out; 
      },

      key() {
          // alternative to this implementation is to count SHARPS AND FLATS
        var out = [-1];
        var chunks = this.chordChunks();
        // get only chord lines
        out = chunks.map(item => item.lines.filter(l => l[1] === 'chord').map(l => l[0]).join(' ').split(/\s+/) );
        // unique and non-empty chords
        out = out.map(item => item.filter((l,idx) => l && item.indexOf(l) === idx));
        // get intervals between natural order (intervals not used prob delete soon TODO)
        var intervals = out.map(item => this.noteIntervals(item).filter(item => item && item!==-1));
        // TODO check all intervals between notes to find likely chord by having P4, P5 and mostly m\d
        var keyCounts = out.map(item => {
            // map all tonal relationships to find most harmonious for key
            return item.map(chd => {
                var prog = chord(chd).buildProgession();  // build progression using current chord as tonic
                //console.log('prog',prog);
                let intervals = item.map(chd2 => 
                    chord().noteInterval(chd,chd2)
                );
                let founds = item.map(chd2 => 
                    prog.filter(p1 => p1 === chd2.toLowerCase()).length
                );
                let fifths = item.map(chd2 => 
                    prog.filter((p1,pos) => p1 === chd2.toLowerCase() && pos === 4).length
                );
                let fourths = item.map(chd2 => 
                    prog.filter((p1,pos) => p1 === chd2.toLowerCase() && pos === 3).length
                );
                var keyMatrix = [chd,founds.reduce((acc,cnt) => acc+cnt,0),fourths.reduce((acc,cnt) => acc+cnt,0),fifths.reduce((acc,cnt) => acc+cnt,0)];
                //console.log(chd, 'founds',founds,'fifths',fifths, 'fourths', fourths);
                //console.log('keyMatrix',keyMatrix);
                intervals.unshift(chd);
                return keyMatrix; // counts by key
                // return intervals; //distances between each note

            });
        });
        //console.log('intervals',intervals,'map intervals',keyCounts);
        // get the letters of the highest total key counts
        var tally  = keyCounts.map(item => item.map(km => [km[0], km[1] + km[2] + km[3]]).sort((a,b) => b[1]-a[1])[0][0]);
        
        // remove bass from remain
        tally = tally.map(k => k.replace(/\/.+$/g,''))
        //console.log('tally',tally);
        var out = tally.filter((item,idx) => item?.length<4 &&  tally.indexOf(item) === idx); //remove repeats, otherwise for each chunk

        if (!out.length) {
          return [];
        } else if (out.length === 1 || !out[0].match(/[m0-9]/i)) {
          // if the first value is a straight chord then just return that
          return [out[0]];
        } else if (!out[1].match(/[m0-9]/i)) {
          // return the second value if not m or 7th,9th, etc
          return [out[1]];
        } else {
          return [out[0]];
        }

        return out;
      },

      chordChunks() {
        return this.songChunks().filter(c => !c.label.toLowerCase().match(/chords$/) && c.lines.filter(l => l[1] === 'chord').length);
      },
      namedSongParts() {
        // names song parts
        return 'chorus,verse,intro,introduction,outro,bridge,prechorus,interlude,instrumental,solo'.split(/,/g);
      },
      findSongPartLabel(str) {
        // get the control name for a song part if indexOf in a string
        var out = this.namedSongParts().map(part => str.toLowerCase().indexOf(part) > -1 ? part : false).filter(part => part);
        if (out.length > 0) {
          return out[0];
        } else {
          return false;
        }
      },
      songPartLabel(lbl) {
        let test = lbl?.toLowerCase().trim();
        let out = false;
        if (test === 'verse') {
          out ='V';
        } else if (test === 'chorus') {
          out ='C';
        }  else if (test === 'bridge') {
          out = 'B';
        } else if (test === 'intro') {
          out = 'I';
        } else if (test === 'introduction') {
          out = 'I';
        } else if (test === 'outro') {
          out = 'O';
        }  else if (test === 'interlude') {
          out = 'I';
        } else if (test === 'instrumental') {
          out = 'I';
        }  else if (test === 'prechorus') {
          out = 'PC';
        } 
        return out;
      },
      verseChorusBridge() {

        // returns V-V-C1-C2-V-V-C1-C2-V-V
        var out ='';
        if (this.verseChorusCache.length) {
          out = this.verseChorusCache;
        } else { 
          out =  this.verseChunks().map(chunk => chunk.verseChorusBridge);
          
          // check the lyrics for special type names, but really do this in the early paring when the control type is formed
          var keywords = this.verseChunks().map( chunk => chunk.lines.filter(l => l[1] === 'lyric')).map(l => l.join('\n'));
          
          // lines length as markers 


          // control version is important here
          out = out.map(item => this.songPartLabel(item.replace(/[^A-Z]/gi,'')) || 'V' ); // or Bridge if unique within song or Chorus if it repeats
          //console.log('kw',out);
          this.verseChorusCache = out;
        }  
        return out;

      },
      verseChunks() {
        var minIndex = this.firstChordParagraph();
        var out = this.songChunks().filter(c => c.label.toLowerCase() !== 'strumming' && c.lines.filter(l => l[1] === 'lyric').length);
        // if no chords for verse paragraphs at least two behind, then align starts
        if(minIndex && out[0] && out[0].label.match(/^[0-9]+/) && out[0].label.match(/^[0-9]+/)[0]  - minIndex <= -2) {
          out = out.filter(c => {
            let thisIdx = c.label.match(/^[0-9]+/);
            if (thisIdx) thisIdx = thisIdx[0];
            //console.log('minIndex',minIndex, thisIdx);
            return thisIdx - 0 >= minIndex - 0;
          });
        }
        return out;
      },
      
      chordFactor() {
        // gets the unique chord profile from each chunk
        var all = this.chordChunks();
        var out = [];
        var listOfFactors = [];
        
        // combine and compare w existing before adding
        // get only chord lines from the chunks

        // only let though valid chords
        all.forEach(item => item.lines = item.lines.filter(l => l[1] === 'chord').map(item2 => [   item2[0].split(/\s+/g).filter(c => chord(c).valid()).join(' ')   ,   item2[1]   ] ));

        all.forEach(item => {
          // create string of chords for combine removing sequential duplicates from space and then from join
          var flatChords = item.lines.map(item => item[0].replace(/^|\s+|$/ig,' ')).join(' ').replace(/^|\s+|$/ig,' ').replace(/(\s*\w+\s)\1+/gi,'$1').trim();
          //console.log('flatChords',flatChords);
          var foundIndex = listOfFactors.indexOf(flatChords);
          if (foundIndex === -1){
           listOfFactors.push(flatChords);
           //console.log('listOfFactors',listOfFactors);
           out.push(item);
          } else {
            out[foundIndex].label = [out[foundIndex].label,item.label].join(',');
          }
        });
        //console.log(listOfFactors);
        //var out = all.filter(item => item);
        //{chunk.lines.filter(l => l[1] === 'chord').map(l => <li>{l[0]}</li>)}
        return out;
      },

      chordBunch() {
        // if the bunch is large, preserve the original line breaks
        var out = [];
        var chordStr = this.chordFactor().map(item => item.lines.map(l => l[0])).flat(Infinity).join(' ').replace(/\s+/g,' ').replace(/(\s*\w+\s*)\1+/gi,'$1');
        out = chordStr.match(/((?:\S+ ){2,14})\1/g);
        let regexbunch = chordStr.replace(/((\w+\s)+)\1/g,'$1');
        console.log('chordBunch',out,'chordStr',chordStr,'regexbunch',regexbunch);
        if (!out) return [];
        out =  out.filter((item,idx) => out.indexOf(item) === idx);
  
        
        // find how many repeats are here
        out = out.map(item => {
          var theseNotes = item.split(/\s+/).filter(i2 => i2.length > 0);
          for(var repeatLen = 2; repeatLen < Math.floor(theseNotes.length/2) + 1 ; repeatLen++) {
            //console.log('forrepeatLen',repeatLen,'these note len',theseNotes.length);
            var found = false;
            var parts = theseNotes.length / repeatLen;
            if ( theseNotes.length % repeatLen === 0 ) {
               found = true;
                // split by these links and compare
                for (var curNote = 0; curNote < repeatLen; curNote++) {
                  for (var p = 1; p < parts; p++) {
                   //console.log('chordBunchCompare',theseNotes[curNote],theseNotes[curNote + repeatLen * p]);
                    if (theseNotes[curNote] !== theseNotes[curNote + repeatLen * p] ) {
                        found = false;
                    }
                  }
                }
            }
            if (found) {
              //console.log('repeatLen',repeatLen);
              break;
            }
          }
          return theseNotes.slice(0,repeatLen).join(' ');
      });
      return out.filter((item,idx) => out.indexOf(item) === idx);  
        /*
        out.forEach(item => {
          var chords = item.split(/\s+/).filter(item => item.length);
          var pos = 0;
          console.log('chordBunch chords', chords,chords[0]);
          while(chords.indexOf(chords[0],pos) > -1) {
            pos = chords.indexOf(chords[0],pos);
            console.log('pos', pos);
            pos++;
          }
        });
        */
        return out;
      },
      
      lineType(lineText) {
        // empty/control/lyric/chord/break/instrumental/interlude
        var out = 'empty';
        if (lineText.replace(/\s/g,'').length === 0 ) {
          return out;
        }
  
        var parts = lineText.split(/\s+/).filter(item => item.length > 0);
  
        if(lineText.trim().match(/^\[[^\]]+\]$/)) {
          out = 'control';
        } else if (lineText.match(/^Capo/) || lineText.match(/^\s*[A-Z]+\s*:/i) || lineText.match(/^Key:/) || lineText.match(/^Tuning:/) || lineText.match(/^Difficulty:/) || lineText.match(/^STRUMMING/) || lineText.match(/^CHORDS$/)) {
          out = 'control';
        } else if (lineText.match(/^Author/) || lineText.match(/\d+ views,/) || lineText.match(/add to favorites/) || lineText.match(/for this song yet/)) {
          out = 'stat';
        } else if (lineText.match(/((\d+?|x)( |-)?){6}/i) ) {
          out = 'voicing';
        } else if (lineText.match(/-{3,}/) || lineText.match(/(\d-){2,}/)) {
            out = 'tab';
            // the match below are separators found often, high end tried .75, see also chordLines(), aug09 decreased from .65
        } else if (parts.length > 0 && parts.filter(item => chord(item.replace('|','')).valid() || item.match(/^[\|,\-\[\]]$/) ).length > parts.length * .5) {
          out = 'chord';
        } else if (lineText.match(/\bchords by\b/) || lineText.match(/^\s*title\s*:/i)) {
          out = 'title';
        } else if(lineText.match(/^\d+$/)) {
          out = 'unknown';
        } else if (lineText.match(/[a-z]{3,}/ig) && lineText.match(/[a-z]/ig).length  > [lineText.match(/[0-9]/ig)].flat(1).length * 2) {
          // more letters than numbers
          out = 'lyric';
        } else {
          out = 'unknown';
        }
        return out;
      }
    };
    return out;
  }
  export default song;