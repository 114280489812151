import React from 'react';
import chord from './chord';
/*
common guitar shapes for chord model 
X also show shapes with four fret range as list
X limit mute chords within shape - ie shape must be contiguous with an egde of the intrument
, also toggle inversions on off
[[-1,0,-1,-1,-1,0],[-1,-1,2,2,2,-1]]

*/
class FretShape extends React.Component {
  
  minFret() {
    return this.voicing().reduce((acc,fret) => (fret > -1 && fret<acc) || acc<0 ? fret : acc,-1);
  }
  voicing() {
    return this.props.voicing?.map?.( v => v === 'x' || v === 'X' ? -1 : parseInt(v));
  }
  shapeNotes() {
    return this.voicing().map((fret,string) => instrument().note(string,fret));
  }
  inChord(string,fret) {
      // get notes by interval position
      var ch = chord(this.props.chord);
      // get notes by interval position
      let thisNote = instrument().note(string,fret + this.minFret());
      return ch.notes().indexOf(thisNote) !== -1;
  }
  tinyW() {
    return 70;
  }
  knobRotation(string,fret) {
    // get notes by interval position
    var ch = chord(this.props.chord);
    // get notes by interval position
    let thisNote = instrument().note(string,fret);
    let idx = ch.notes().indexOf(thisNote);
    var outDeg = ch.intrvl().indexOf(ch.notes(true)[idx]) * 30;
    return outDeg;
  }
  colorDark() {
    return 'hsl(272, 63%, 14%)';
  }
  colW() {
    return 66;
  }
  marginL() {
    return 50;
  }
  rowH() {

  }
  renderTiny() {
    return(<div className={['fretShape',this.props.selected && 'on'].join(' ')} onClick={() => this.props.onSelect?.(this.props.selected ? false : this.voicing()) }>
    <svg width={this.props.width || (610 * .2)} height={(this.props.width && this.props.width * 515/610) || (515 * .2)} viewBox={[0,0,610,515]}>  

    {new Array(6).fill().map((v,idx) => <rect width={10} height={406}  x={idx* this.colW() + this.marginL() - 5} y={0} style={{fill:this.colorDark()}} />) }

    <rect width={this.colW() * 5} height={6}  x={45} y={0} style={{fill:this.colorDark()}} /> 
    <rect width={this.colW() * 5} height={6}  x={45} y={100} style={{fill:this.colorDark()}} /> 
    <rect width={this.colW() * 5} height={6}  x={45} y={200} style={{fill:this.colorDark()}} /> 
    <rect width={this.colW() * 5} height={6}  x={45} y={300} style={{fill:this.colorDark()}} /> 
    <rect width={this.colW() * 5} height={6}  x={45} y={400} style={{fill:this.colorDark()}} /> 

    {new Array(4).fill().map((vy,idy) => [3,5,7,9,12].indexOf(idy + this.minFret()) > -1 && <rect width={this.colW() * 5} height={100}  x={45} y={idy*100} style={{fill:'hsla(272, 63%, 99%,.07)'}}/>)}

    {new Array(4).fill().map((vy,idy) =>
    new Array(6).fill().map((v,idx) =>
      (
        (this.voicing()[idx] - this.minFret() === idy ) && <g>
            <circle cx={idx * this.colW() + this.marginL()} cy={idy * 100 + 50} r={26}  style={    {fill: this.props.dark && this.colorDark() || 'hsl(93, 3%, 77%)'  }    }/>
          </g>
      ) 
      || (idy ===0 && this.voicing()[idx] < 0 &&  
        <g style={{ fill:this.props.dark ? this.colorDark() : 'hsl(93, 3%, 77%)'}}>
        <rect width={16} height={80} x={idx * this.colW() + this.marginL() - 8 } y={10}  transform={'rotate(36 ' +  (idx * this.colW() + this.marginL()) + ' 50)'}/>
        <rect width={16} height={80} x={idx * this.colW() + this.marginL() - 8} y={10}  transform={'rotate(-36 ' + (idx * this.colW() + this.marginL()) + ' 50)'}/>
      </g>)
      
    ))}
    
    <text x={this.colW() * 5 + this.marginL() +44} y={90} text-anchor="start" width={100} height={120} style={{fill:this.colorDark(),fontSize : 120, fontWeight:'bold'}}>{this.minFret()}</text>
    
    
  </svg></div>);

  }
  // five fret view of the instrument    
  render() {
    if (this.props.width < this.tinyW()) {
      return this.renderTiny();
    }
    return(<div className={['fretShape',this.props.selected && 'on'].join(' ')} onClick={() => this.props.onSelect?.(this.props.selected ? false : this.voicing()) }>
      {1===2 && <div>{this.voicing().join('')}</div>}
      {1===2 && <div>{this.shapeNotes().join('')}</div>}
      {1===2 && <div>ease: {this.props.ease}</div>}

      <svg width={this.props.width || (720 * .2)} height={(this.props.width && this.props.width * 600/720) || (600 * .2)} viewBox={[0,0,720,600]}>  

      {this.props.showStringLines && new Array(6).fill().map((v,idx) => <rect width={12} height={600}  x={idx*100 + 44} y={0} style={{fill:'hsla(109, 3%, 72%,.3)'}} />) }
      
      {new Array(5).fill().map((vy,idy) =>
      new Array(6).fill().map((v,idx) =>
        (
          (this.voicing()[idx] - this.minFret() === idy || (idy === 0 && this.voicing()[idx] > 0)) && <g>

              {(this.voicing()[idx] - this.minFret() === idy || this.inChord(idx,idy)) && <mask id={'mask_' + this.props.voicing.join('z').replace(/\-1/g,'x') + '_' + idx + '_' + idy}>
                <circle cx={idx * 100 + 50} cy={idy * 100 + 50} r={50}  fill="white"/>
                <rect x={idx * 100 + 38} y={idy * 100 + 0} width={24} height={40}  fill="black" />
              </mask>}

              <circle cx={idx * 100 + 50} cy={idy * 100 + 50} r={50}   transform={'rotate(' + this.knobRotation(idx,idy + this.minFret()) + ' ' +  (idx * 100 + 50) + ' ' + (idy * 100 + 50) + ')'} mask={(this.voicing()[idx] - this.minFret() === idy || this.inChord(idx,idy)) && 'url(#mask_' + this.props.voicing.join('z').replace(/\-1/g,'x') + '_' + idx + '_' + idy + ')' || false} style={    {fill: this.props.dark && ['hsl(272, 63%, 34%)','hsl(272, 63%, 38%)','hsl(272, 63%, 42%)','hsl(272, 63%, 46%)','hsl(272, 63%, 50%)'][idy] || ['hsl(93, 3%, 77%)','hsl(109, 0%, 81%)','hsl(109, 3%, 85%)','hsl(109, 3%, 89%)','hsl(109, 3%, 95%)'][idy]  }    }/>
              
            </g>
        ) 
        || (idy ===0 && this.voicing()[idx] < 0 &&  
          <g style={{ fill:this.props.dark ? 'hsl(272, 63%, 34%)' : 'hsl(93, 3%, 77%)'}}>
          <rect width={24} height={90} x={idx * 100 + 38} y={5}  transform={'rotate(36 ' +  (idx * 100 + 50) + ' 50)'}/>
          <rect width={24} height={90} x={idx * 100 + 38} y={5}  transform={'rotate(-36 ' + (idx * 100 + 50) + ' 50)'}/>
        </g>)
        
      ))}
      
      <text x={620} y={82} width={100} height={100} style={{fill:'hsl(109, 3%, 72%)',fontSize : 90, fontWeight:'bold'}}>{this.minFret()}</text>
      <text x={620} y={300 + 82} width={100} height={100} style={{fill:'hsl(109, 3%, 72%)',fontSize : 90, fontWeight:'bold'}}>{this.minFret()+3}</text>
      
    </svg></div>);
  }
}
class ChordStar extends React.Component {
  tonicAngle() {
    var out = 0;
    if (this.props.songKey) {
      var ch = chord(this.props.chord);
      var keyCh = chord(this.props.songKey);
      let diff = ch.noteDiff(ch.root(),keyCh.root());
      out = diff * 30;
    } 
    return out;
  }
  onNotesMap() {
    var ch = chord(this.props.chord || 'A');
    // get notes by interval position
     
     var outDeg = ch.notes(true).map(n => ch.intrvl().indexOf(n) * 30);
     // get the diff between root notes of chord and songKey to find the diff
     if (this.props.songKey) {
       var keyCh = chord(this.props.songKey);
       let diff = ch.noteDiff(ch.root(),keyCh.root());
       //console.log('diff',ch.root(),keyCh.root(),diff, outDeg.join('-'));
       outDeg = outDeg.map(v =>  v + diff * 30);
       //console.log('outDeg',outDeg.join('-'));
     } 
    return outDeg;
    //return [0,120,210];
  }
  labels() {
    if (this.props.songKey) {
      return (this.props.songKey + ',m2,M2,m3,M3,P4,d5,P5,m6,M6,m7,M7').split(/,/);
    } else {
      return 'T,m2,M2,m3,M3,P4,d5,P5,m6,M6,m7,M7'.split(/,/);
    }
  }
  color() {
    return this.props.color || 'hsl(109, 0%, 75%)';
  }
  backgroundColor () {
    return this.props.backgroundColor || 'hsl(272, 64%, 28%)';
  }
  render() {
    return(
      <div className="chordStar">
        {1===2 && <div>{chord(this.props.chord).notes().join(' ')} </div>}
        {1===2 && <div>{chord(this.props.chord).notes(true).join(' ')} </div>}
        <svg width={this.props.width || 180} height={this.props.width || 180} viewBox={[-40,-40,480,480]}>

          {(this.props.width && this.props.width < 100) &&  <circle cx={200} cy={200} r={180} style={{ fill: this.backgroundColor()}}/>}
          {(!this.props.width || this.props.width >= 100) && [0,30,60,90,120,150,180,210,240,270,300,330].map((angle,idx) =>
          <rect key={idx} x={200} y={176} width={150} height={48}  transform={'rotate(' + angle + ' 200 200)'} style={{ fill:'hsl(273, 64%, 42%)'}}/>
          )}
          {(1===2 && this.props.width && this.props.width < 100) && [0,90,180,270].map((angle,idx) =>
          <rect key={idx} x={200} y={176} width={150} height={48}  transform={'rotate(' + angle + ' 200 200)'} style={{ fill:'hsl(273, 64%, 42%)'}}/>
          )}
          
          {this.onNotesMap().map((angle,idx) => 
            angle !== this.tonicAngle() && <rect key={idx} x={200} y={170} width={160} height={60}  transform={'rotate(' + (angle-90) + ' 200 200)'} style={{ fill: this.color()}}/>
            || 
            <polygon key={idx} transform={'rotate(' + (angle-90) + ' 200 200)'} points="200,170 344,170 374,200 344,230 200,230" style={{ fill: this.color()}}/>
            
          )}
          
          {this.labels().map((lbl,idx) => 
            (!this.props.width || this.props.width > 100) && <text key={idx} x={!idx && !this.props.songKey ? 175 : 185} y={18} width={180} height={48}  transform={'rotate(' + (idx * 30 ) + ' 200 200)'} style={{ fill:'hsl(273, 64%, 42%)', fontWeight:'bold',fontSize:20}}>{lbl}</text>
            )}

          {this.labels().map((lbl,idx) => 
            (!this.props.width || this.props.width < 100) && this.onNotesMap().indexOf(idx*30) > -1 && <text key={idx} x={200} y={10} width={180} height={48} textAnchor="middle" transform={'rotate(' + (idx * 30 ) + ' 200 200)'} style={{ fill:this.backgroundColor(), fontWeight:'bold',fontSize:54}}>{lbl}</text>
            )}
        </svg>
        </div>
    );
  }
}
//accepts a fret list and then determines ease to play 
function fretEase(inChd) {
  // finds difficult of freting a chord based on clusters, fret distances, frets pressed, and strings held, and strings played
  // input should be in format [6,5,4,3,2,1] whereas a -1 or 'X' denote an uploaded string,
  // length in not limited to support all stringed instruments

  let stringsHeld = inChd.filter(chd => parseInt(chd) > 0).length; // count only held strings
  let stringsPlayed = inChd.filter(chd => parseInt(chd) > -1).length; 
  let fretsHeld = inChd.filter(chd => parseInt(chd) > 0).filter((chd,idx) => inChd.indexOf(chd) === idx).length; // how many different frets are held
  // how many groups of strings held on same fret adjacted for example [-1,0,2,2,2,0] is one contiguousFret groups (bc zeros on the sides don't impact)
  // filter zeros AFTER on this one
  let nonContiguousFrets = inChd.filter((chd,idx) => inChd[idx-1] !== chd).filter(chd => parseInt(chd) > 0).length;
  let fretDistance = inChd.filter(chd => chd > 0).sort().reverse()[0] - inChd.filter(chd => chd > 0).sort()[0];
  //let cascadeFrets = // very next fret how and over
  
  //TODO fretDistance > three matters... a lot, after 6 is reaching impossible?
  // TODO frets closer to the player are easier to hold
  // TODO familiar patterns can offset this
  let outScore = fretsHeld + nonContiguousFrets/.4 + stringsHeld + fretDistance/2 ;

  //console.log('fretEase',stringsHeld,stringsPlayed,fretsHeld,nonContiguousFrets, outScore,fretDistance);
  return Math.round(outScore);
} 
class FretMap extends React.Component {
  noteAtFret(str, fret) {
    return this.props.fretted.filter(item => item[1] === str && item[2] === fret)[0][0];
  }
  interval(note) {
    var idx = this.props.notes?.indexOf(note);
    return this.props.intervals?.[idx];
  }
  familiarShapes() {
    var out = `A,X02220
    Am,X02210
    A7,X02020
    Am7,X02010
    AM7,X02120
    B7,X21202
    D,XX0232
    Dm,XX0231
    E,022100
    Em,022000
    Em7,02000
    F,XX3211`.split(/\n+/).map(v => v.replace(/\s/g,'').split(/,/));
    return out;
  }
 

  checkFretSpan(inChord,spanMax) {
    var valid = false;
    //var min = inChord.reduce((acc,val) => (val > 0 && (val < acc || acc < 0)) ? val  : acc,-1); // not zero or empty (include open strings otherwise out of reach)
    var min = inChord.reduce((acc,val) => (val >= 0 && (val < acc || acc < 0)) ? val  : acc,-1); // not  empty
    var max = inChord.reduce((acc,val) => val > acc || acc < 0 ? val  : acc,-1);
    //(Math.abs(final[s] - final[s-1]) <= maxFretSpan)
    //if (max - min <= spanMax || min < 1) { // not zero (include open strings otherwise out of reach)
    if (max - min <= spanMax ) {
      valid = true;
    }
    // muted strings not in the middle
    if (inChord[3] === -1 || inChord[4] === -1 || (inChord[1] !== -1 && inChord[2] === -1 )) {
      valid = false;
    }

    // plays at least 4 strings
    var unplayedStrings = inChord.reduce((acc,item) => item < 0 ? acc+1 : acc,0);
    if (unplayedStrings > 2) {
      valid = false;
    }
    return valid;
  }
  groupedNotes() {
    // find chords combos reached 4 frets apart
    // get all possible combos
    // limit on tonic in the bass
    // limit on gaps
    // favor use more strings, comfortable/familiar shapes
    var maxFretSpan = 3;
    var out = new Array(this.props.instrument.frets).fill('x').map((item,idx) =>  
          this.props.instrument.tuning.map((str,idy) => this.props.fretted.filter(item => item[1] === idy && item[2] === idx).length > 0 ? this.noteAtFret(idy,idx) : -1
    ))
    //console.log('mapped group notes out:',out);
    var onlyFretted = this.props.instrument.tuning.map((str,idy) => 
      new Array(this.props.instrument.frets).fill('x').map((item,idx) => this.props.fretted.filter(item => item[1] === idy && item[2] === idx).length > 0 ? idx : -1 ).filter(item => item !== -1)
    );
    
    // seed the build with an empty chord map 
    var lastChartout = [[-1,-1,-1,-1,-1,-1]];
    // for all instrument strings
    for (let s = 0; s < onlyFretted.length;s++) {
      // take the values found on this string
      let chartOut = onlyFretted[s].map(fret => { var stringOut = [-1,-1,-1,-1,-1,-1]; stringOut[s] = fret; return stringOut;} );
      // and expand on the arrays we are build from previous strings, ES6 spread operator copies arrays
      chartOut = lastChartout.map(item => chartOut.map(chOut => {var final = [...item];final[s] = chOut[s];return final;})).flat();//expand on previous work
      // append to build chords
      lastChartout = lastChartout.concat(chartOut);
    }
    //console.log('maxedout out:',onlyFretted);
    lastChartout = lastChartout.filter(chord => this.checkFretSpan(chord,maxFretSpan));

    // has all the notes 
    var notes = chord(this.props.chord).notes();
    lastChartout = lastChartout.filter(chord => {  
      var chordNotes = chord.map((fret,str) => fret > -1 ? this.noteAtFret(str,fret) : -1 ); 
      // all notes required with correct bass?
      var bassNote = chordNotes.filter(fret => fret!==-1)[0];
      // only return true if the fretting(chordNotes) has same amount of notes as chord profile
      var noteCount = chordNotes.reduce( (acc,v,index) => v !== -1 && chordNotes.indexOf(v) === index ? acc+1 : acc,0);
      return   noteCount  ===   parseInt(notes.filter((n,idn) => notes.indexOf(n) === idn).length) && bassNote === notes[0] ? true : false      
    });
    
    // finally if there is a longer version of this same chord, then drop if so
    lastChartout = lastChartout.filter((chord,idx) =>        !lastChartout.filter((chord2,idx2) =>  idx !== idx2 && chord2.join('z').match(  new RegExp(chord.join('z').replace(/-1/g,'[^z]+')  ) )    )?.length );

    // match to known relative shapes
    //console.log('lastChartout:',lastChartout);
    return lastChartout; 

  }
  // order by most notes fretted (muted string chords at end)
  //{this.groupedNotes().sort((a,b) => a.reduce((acc,v) => v === -1 ? acc+1 : acc,0) - b.reduce((acc,v) => v === -1 ? acc+1 : acc,0)).map(g => 
  // new order by fret position
  familarShapeList() {
    
    var out = this.groupedNotes();
    out = out.map(g => { 
      var minFret = g.reduce((acc,v) => acc === -1 || (v !== -1 && v < acc) ? v: acc,-1);
      var zeroAligned = g.map( v=> v !== -1 ? v - minFret : 'X');
      console.log('sh zeroalign',zeroAligned.join(''),this.familiarShapes());
      var matchShapes = this.familiarShapes().filter(sh => sh[1] === zeroAligned.join(''));  
      
      return matchShapes?.length ? [minFret, matchShapes[0]?.[0]] : false;
    }).filter(v => v);
    return out;
  }
  fretHt() {
    return 140;
  }
  circleR() {
    return 40;
  }
  svgHt() {
    return 2254;
  }
  circleX(idy) {
    return (720/(this.props.instrument.tuning.length + 1)) * (idy+1);
  }
  circleY(row) {
    return this.fretHt() * row + this.fretHt()/2;
  }
  knobRotation(string,fret) {
    // get notes by interval position
    var ch = chord(this.props.chord);
    // get notes by interval position
    let thisNote = instrument().note(string,fret);
    let idx = ch.notes().indexOf(thisNote);
    var outDeg = ch.intrvl().indexOf(ch.notes(true)[idx]) * 30;
    return outDeg;
  }
  color(idx = false,fret = false) {
    // is this history, is it highlighted voicing, or this frame on?
    var out = this.props.color || 'hsl(272, 63%, 28%)';
    if (idx!== false && fret!==false) {
      if (this.voicing()?.[idx] === fret ) {
        out = this.onColor();
      } 
    }
    return out; 
  }
  voicing() {
    
    var out = this.props.selectedVoicing?.split(',');
    if (out) {

      return out.map?.( v => v === 'x' || v === 'X' ? -1 : parseInt(v));
    } else {
      return false;
    }
    //return this.props.voicing?.map?.( v => v === 'x' || v === 'X' ? -1 : parseInt(v));
  }
  prevVoicing() {
    var out = this.props.prevVoicing?.split(',');
    if (out) {

      return out.map?.( v => v === 'x' || v === 'X' ? -1 : parseInt(v));
    } else {
      return false;
    }
  }
  onColor() {
    return 'hsl(42, 67%, 53%)';
  }
  lightColor() {
    return 'hsl(272,63%,54%)';
  }
  ghostColor() {
    return 'hsla(272,63%,94%,.2)';
  }
  selectChordByNote = (fret,xStr) => {
    var gn = this.groupedNotes().filter(vc => vc[xStr] === fret);
    let easeIndex = gn.map((vc,idx) => [fretEase(vc),idx]);
    easeIndex.sort((a,b) => a[0] - b[0]);
    //console.log(fret,xStr,this.groupedNotes(),gn, easeIndex);
    if (gn.length) {
      var eIdx = easeIndex[0][1];
      //this.props.chord
      this.props.onSelect?.(this.props.chord,gn[eIdx]);
    }  
  }
  renderSVG() {
    // add here to highlight voicing on fretboard TODO 
    // also show history (prev/next) using gray/composite primary color scheme
    return(<div className="svgFretMap">
    
    <svg width={this.props.width || (720 * .1)} height={(this.props.width && this.props.width * this.svgHt()/720) || (this.svgHt() * .1)} viewBox={[0,0,720,this.svgHt()]}>  
    {this.props.instrument.tuning.map((v,idx) => <rect fill={'hsla(0,0%,0%,.5)'} x={(720/(this.props.instrument.tuning.length + 1)) * (idx+1) -5} y={this.fretHt()} height={this.svgHt()} width={10}/>)}        
    
    {this.prevVoicing().map?.((fret,xstr) => <circle cx={this.circleX(xstr)} cy={this.fretHt() * fret + this.fretHt()/2} r={this.circleR() * 1.2}    style={    {fill: this.ghostColor()}    }/>)}
    
    <rect fill={'hsla(0,0%,0%,.5)'} x={(720/(this.props.instrument.tuning.length + 1)) -5} y={this.fretHt() - 10} height={10} width={720 - (720/(this.props.instrument.tuning.length + 1) *2    ) + 10}/>

      {new Array(18).fill('x').map((item,idx) => <g>      
          {idx > 0 && <rect fill={'hsla(0,0%,0%,.5)'} x={(720/(this.props.instrument.tuning.length + 1))} y={this.fretHt() * idx} height={14} width={720 - (720/(this.props.instrument.tuning.length + 1) *2    )}/>}

          {(idx===3 || idx === 5 || idx === 7 || idx === 9 || idx === 12 || idx === 15 ) && <rect fill={'hsla(0,0%,100%,.07)'} x={(720/(this.props.instrument.tuning.length + 1))} y={this.fretHt() * idx} height={this.fretHt()} width={720 - (720/(this.props.instrument.tuning.length + 1) *2    )}/>}

          {(idx === 5 || idx === 9 ) && <text x={(720)} y={this.fretHt() * (idx +1) - 30} width={100} height={100} text-anchor="end" style={{fill:this.color(),fontSize : 90}}>{idx}</text>}

          {this.props.instrument.tuning.map((str,idy) => <g>
            {this.props.fretted.filter(item => item[1] === idy && item[2] === idx).length > 0 && <g>
            <mask id={'mask_' + this.props.chord + '_' + idx + '_' + idy}>
              <circle cx={this.circleX(idy)} cy={this.circleY(idx)} r={this.circleR()}  fill="white"/>
            </mask>

            <g onClick={ev => this.selectChordByNote(idx,idy)} transform={'rotate(' + this.knobRotation(idy,idx) + ' ' +  (720/(this.props.instrument.tuning.length + 1)) * (idy+1)  + ' ' + (this.fretHt() * idx + this.fretHt()/2) + ')'} mask={'url(#mask_' + this.props.chord + '_' + idx + '_' + idy + ')'}>
              <circle cx={this.circleX(idy)} cy={this.fretHt() * idx + this.fretHt()/2} r={this.circleR()}    style={    {fill: this.color(idy,idx)}    }/>
              {idx === 0 && <circle cx={this.circleX(idy)} cy={this.fretHt() * idx + this.fretHt()/2} r={this.circleR() * .68}    style={    {fill: this.lightColor()}    }/>}
              <rect x={this.circleX(idy) - 10} y={this.circleY(idx) - this.circleR()} width={20} height={36}  fill={idx === 0 && this.color(idy,idx) || this.lightColor()} />
            </g>  

              {1===2 && <circle cx={this.circleX(idy)} cy={this.fretHt() * idx + this.fretHt()/2} r={this.circleR()} className={this.interval(this.noteAtFret(idy,idx)) + 'Note'} fill={'hsla(0,0%,0%,.5)'}/>}
            </g>}

            
          </g>)}
        </g>)}
        
    </svg>
    </div>);
  }
  // <FretShape voicing={g} chord={this.props.chord} width={120}/>
  renderTextual() {
    
    return(<div className="textualFretMap" >
      <div className="familiarShapes">
        
      {1===2 && this.familarShapeList().length > 0 && <div># Shape</div>}
      {1===2 && this.familarShapeList().sort((a,b) => a[0] - b[0]).map(s => <div>{s[0]} {s[1]}</div>)}
      </div>

      {this.groupedNotes().sort((a,b) => a.reduce((acc,v) => acc === -1 || (v < acc && v !== -1) ? v : acc,-1) - b.reduce((acc,v) => acc === -1 || (v < acc && v !== -1) ? v : acc,-1)).map(g => 
            
            
            <FretShape width={50} voicing={g} ease={fretEase(g)} chord={this.props.chord} selected={this.props.selectedVoicing && this.props.selectedVoicing === g.join(',')} dark={true}  onSelect={(voicing) => this.props.onSelect?.(this.props.chord,voicing) }/>
      )}
      
      {1===2 && new Array(this.props.instrument.frets).fill('x').map((item,idx) => <div>
            <div className="fretNum">{idx}</div>
            {this.props.instrument.tuning.map((str,idy) => <span>
              {this.props.fretted.filter(item => item[1] === idy && item[2] === idx).length > 0 && 
                <span className={this.interval(this.noteAtFret(idy,idx)) + 'Note'}>{this.noteAtFret(idy,idx)}</span> || <span>-</span>
              }
            </span>)}
          </div>)}
    </div>);

    
  }
  renderHorizontal() {
    return(<div className={['fretMap','horizontal',this.props.size || 'medium'].join(' ')}>
    <table className="grid">
      <tbody>
          {this.props.instrument.tuning.map((str,idy) => <tr>
            {new Array(this.props.instrument.frets).fill('x').map((item,idx) => <td>
            {this.props.fretted.filter(item => item[1] === Math.abs(idy - this.props.instrument.tuning.length +1) && item[2] === idx).length > 0 && 
              <span className={this.interval(this.noteAtFret(Math.abs(idy-this.props.instrument.tuning.length + 1),idx)) + 'Note'}>{this.noteAtFret(Math.abs(idy-this.props.instrument.tuning.length + 1),idx)}</span>
            }
        </td>)}
        </tr>)}
        <tr>
        {new Array(this.props.instrument.frets).fill('x').map((item,idx) => <td>{idx}</td>)}
        </tr>
      </tbody>
    </table>
  </div>);
  }
  render() {
    if (this.props.orientation === 'horizontal') {

      return this.renderHorizontal();
    } else if(this.props.orientation === 'textual') {
      return this.renderTextual();
    } else if(this.props.orientation === 'svg') {
      return this.renderSVG();
    } else {
    return(<div className={['fretMap','vertical',this.props.size || 'medium'].join(' ')}>
      <table className="grid">
        <tbody>
          {new Array(this.props.instrument.frets).fill('x').map((item,idx) => <tr>
            <td>{idx }</td>
            {this.props.instrument.tuning.map((str,idy) => <td>
              {this.props.fretted.filter(item => item[1] === idy && item[2] === idx).length > 0 && 
                <span className={this.interval(this.noteAtFret(idy,idx)) + 'Note'}>{this.noteAtFret(idy,idx)}</span>
              }
            </td>)}
          </tr>)}
        </tbody>
      </table>
    </div>);
    }
  }
}

class PianoMap extends React.Component { 
    // render from a scale model in order to start he sequence correctly
     
    mapNotes() {
      return [chord().allnotes(),chord().allnotes(),chord().allnotes()].flat();
    }
    mapCss() {
      let outCss = this.mapNotes().map(n => '');
      var idx = 0;
      this.props.notes.forEach((n,nIdx) => {
        idx = this.mapNotes().indexOf(n,idx);
        outCss[idx] =  this.props.intervals[nIdx] + 'Note';
      });
      return outCss;
    }
    render() {
      return(<div className={['pianomap', this.props.size || 'medium'].join(' ')}>
        {1===2 && <div>{this.props.notes} {this.props.intervals}</div>}
        <ul className="pianokeys">
          {this.mapNotes().map((note,idx) => 
          <li className={[note.charAt(1) === '#' && 'blackkey', this.mapCss()[idx]].join(' ')}>{note}</li>
          )
  
          }
  
      </ul></div>);
    }
  }

  class HornMap extends React.Component {
    render() {
      return (<div className="hornMap">
        {this.props.fingers.slice(1).map((fing,fidx) => 
          <div className="txtC">{this.props.fingers[0].toUpperCase()}{fidx + 1}
          <ul>
            {new Array(this.props.buttons).fill(' ').map((item,idx) => 
              <li className={fing.split('').indexOf((idx+1).toString()) === -1 ? 'open': 'closed'}> </li>
            )}
          </ul>
          </div>
        )}
        
      </div>);
    }
  } 
  function piano() {
    // show a map of the piano keys for a note or chord
    return {};
  }
  
  function trumpet() {
    // show each notes in trumpet notation, changing for Bb instrument profile
    // https://spinditty.com/learning/Trumpet-Fingering-Chart
    return {
      // these note and fingers arrays align
      notes : ['f#','g','g#','a','a#','b','c','c#','d','d#','e','f',   
      'f#','g','g#','a','a#','b','c','c#','d','d#','e','f',
      'f#','g','g#','a','a#','b','c','c#','d','d#','e','f'],
  
      fingers: ['123','13','23','12','1','2','0','123','13','23','12','1',   
      '2','0','23','12','1','2','0','12','1','2','0','1',
      '2','0','23','12','1','2','0','12','0/1','2','0','1'],
  
      // C on the trumpet is Bb for piano (transpose any note a whole step)
  
      map(mapNotes) {
        // notes don't indicate octave at this time show all octaves
        console.log('map trumpet',this.notes.length, this.fingers.length);
        var out = mapNotes.map (note => {
          let nIdx = chord().noteIndex(note);
          let transNote = chord().noteAtIndex(nIdx + 2);
          var fing = this.notes.map((n,idx) => (n === transNote && this.fingers[idx]) || false).filter(i => i);
          fing.unshift(transNote);
          return fing;
        });
        return out;
      }
    };
  }
  function instrument(tuning) {
    return {
      tuning : tuning || ['e','a','d','g','b','e'],
      frets : 14,
      maxReach: 4,
      map(notes) {
          var out = ['x','x','x','x','x','x'];
          // find positions on fretboard where chord can be played (non-inverted)
          var frets = notes.map(item => {
            // fret is the distance from the tuning to the note
            return this.tuning.map((str,idy) => {
              let out = chord().noteDiff(item, str);
              if (out < 0) { out = out + 12}
              return [item,idy,out];
            });
            
          });
          
          // add another level to these 
          frets = frets.flat();
          frets.map(f => frets.push([f[0],f[1],parseInt(f[2])+12]));
          // console.log('frets', frets);
          return frets;//.flat();
      },
      note(string, fret) {
        if (fret < 0 ) {
          return ' ';
        }
        var noteIndex = chord().allnotes().indexOf(this.tuning[string]) + fret;
        noteIndex = noteIndex >= chord().allnotes().length ? noteIndex % chord().allnotes().length : noteIndex;
        return chord().allnotes()[noteIndex];
      }
    };
  }
  class MIDIPlayer extends React.Component {
    constructor(props) {
      super(props);
      // midi functions
      this.midi = false;
      this.data = false;
      if (navigator.requestMIDIAccess) {
        navigator.requestMIDIAccess({
            sysex: false
        }).then(this.onMIDISuccess, this.onMIDIFailure);
        } else {
         alert("No MIDI support in your browser.");
        }
    }
    onMIDISuccess = (midiAccess) => {
      // when we get a succesful response, run this code
      this.midi = midiAccess; // this is our raw MIDI data, inputs, outputs, and sysex status
      var inputs = this.midi.inputs.values();
      // loop over all available inputs and listen for any MIDI input
      for (var input = inputs.next(); input && !input.done; input = inputs.next()) {
          // each time there is a midi message call the onMIDIMessage function
          input.value.onmidimessage = this.onMIDIMessage;
      }
    }
    onMIDIFailure = (error) => {
      // when we get a failed response, run this code
      console.log("No access to MIDI devices or your browser doesn't support WebMIDI API. Please use WebMIDIAPIShim " + error);
    }
    onMIDIMessage = (message) => {
      this.data = message.data; // this gives us our [command/channel, note, velocity] data.
      console.log('MIDI data', this.data); // MIDI data [144, 63, 73]
    }
    render() {
      return(<div>MIDI</div>);
    }
  }  
  class MusicStaff extends React.Component {
    // the note or chord on the music staff (treble/bass)
    // since notes from chord won't show an octave, center the notes on the staff
    constructor(props) {
      super(props);
      // need to send positionion, clef, and sharp and flat symboles
      this.state = {};
    }
    notesToClef() {
      // accept some notes and then map them to the treble clef
    }
    notes() {
      if (typeof this.props.chord === 'string') {
        var c = chord(this.props.chord);
        var out  = c.notes();
      } else {
        // array of notes
        return this.props.chord;
      }
      
      
      return out;
    }
    chordToFlats() {
      // accept some notes and then map them to the treble clef
      var out = this.notes();
      //out = out.map(n => c.noteIndex(n));
      // 600 is middle c and 0 note index 
      out = out.map(n => n.indexOf('#') > -1);
      return out;
    }
    chordToClef() {
      // accept some notes and then map them to the treble clef
      // each noted should climb higher than the previous note on the music staff
      
      var out = this.notes();
      out = out.map(n => chord().staffIndex(n));
      var last = out;
      var v =0;
      // make sure stacked in order received
      while(v<50) {
        last = out.map(    (stI,idx) => !idx ? stI : ((out[idx-1] > stI && stI+7) || stI)     );
        if (last.join(' ') === out.join(' ')) {
          out = last;
          break;
        } else {
          out = last;
        }
        v++;
      }
      console.log('staff index', out);
      // if staff index less than prev then add min 
      // 600 is middle c and 0 note index 
      out = out.map(n => 600 - n * 50);
      
      //out = out.map(    (stI,idx) => !idx ? stI : ((out[idx-1] < stI && stI-300) || stI)     );
      //out.forEach(    (stI,idx) => {stI = !idx ? stI : ((out[idx-1] < stI && stI-300) || stI)}     );

      return out;
      return [100,150,200,250,300,350,400,450,500,550,600];
    }
    clef() {
      return 'treble';
    }
    col(n) {
      var out = 260;
       if (n === 1) {out = 360;}
       else if (n === 2) {out = 480;}
       else if (n === 3) {out = 580;}
       return out;
    }
     renderSingle() {
       return ( <svg viewBox="0 0 200 100" width={10} height={5} xmlns="http://www.w3.org/2000/svg">
       <g><rect height={12} width={200} fill="black" x={0} y={44}/></g>
       {this.clef() === 'treble' && <text>treble clef</text>}

       {[44].map((y,idx) => <g><rect height={12} width={200} fill="black" x={0} y={y}/></g>
       )}
       <g>

       <ellipse cx="100" cy="50" rx="62" ry="50" mask="url(#masknote)"/>
       <mask id={'masknote'}>
          <ellipse cx="100" cy="50" rx="62" ry="50" fill="white"/>
         <ellipse cx="100" cy="50" rx="28" ry="40" fill="black" transform={'rotate(-15 100 50)'}/>
       </mask>
       </g>
     </svg>);
     }
     color() {
       return this.props.color || 'hsl(136, 5%, 89%)';
     }
     aboveNote(y,idx) {
       var out = false;
       if(idx>0) {
         out = Math.abs(y - this.chordToClef()[idx-1]) < 60;
       }
       return out;

     }
      render() {
        // align the calc with the positioin of middle C to provide some ease for furture expansion last line +100 (700 in this case)

        return             (<div className="musicStaff">{1===2 && chord(this.props.chord).notes()}
        <svg viewBox={[0, 0, 800, 800].join(' ')} width={50} height={50} xmlns="http://www.w3.org/2000/svg">

<svg width={242} xmlns="http://www.w3.org/2000/svg" x={0} y={0} version="1.1" height={660}  viewBox={[0, 0, 15.186, 40.768].join(' ')}>
 <path fill={this.color()} d="m12.049 3.5296c0.305 3.1263-2.019 5.6563-4.0772 7.7014-0.9349 0.897-0.155 0.148-0.6437 0.594-0.1022-0.479-0.2986-1.731-0.2802-2.11 0.1304-2.6939 2.3198-6.5875 4.2381-8.0236 0.309 0.5767 0.563 0.6231 0.763 1.8382zm0.651 16.142c-1.232-0.906-2.85-1.144-4.3336-0.885-0.1913-1.255-0.3827-2.51-0.574-3.764 2.3506-2.329 4.9066-5.0322 5.0406-8.5394 0.059-2.232-0.276-4.6714-1.678-6.4836-1.7004 0.12823-2.8995 2.156-3.8019 3.4165-1.4889 2.6705-1.1414 5.9169-0.57 8.7965-0.8094 0.952-1.9296 1.743-2.7274 2.734-2.3561 2.308-4.4085 5.43-4.0046 8.878 0.18332 3.334 2.5894 6.434 5.8702 7.227 1.2457 0.315 2.5639 0.346 3.8241 0.099 0.2199 2.25 1.0266 4.629 0.0925 6.813-0.7007 1.598-2.7875 3.004-4.3325 2.192-0.5994-0.316-0.1137-0.051-0.478-0.252 1.0698-0.257 1.9996-1.036 2.26-1.565 0.8378-1.464-0.3998-3.639-2.1554-3.358-2.262 0.046-3.1904 3.14-1.7356 4.685 1.3468 1.52 3.833 1.312 5.4301 0.318 1.8125-1.18 2.0395-3.544 1.8325-5.562-0.07-0.678-0.403-2.67-0.444-3.387 0.697-0.249 0.209-0.059 1.193-0.449 2.66-1.053 4.357-4.259 3.594-7.122-0.318-1.469-1.044-2.914-2.302-3.792zm0.561 5.757c0.214 1.991-1.053 4.321-3.079 4.96-0.136-0.795-0.172-1.011-0.2626-1.475-0.4822-2.46-0.744-4.987-1.116-7.481 1.6246-0.168 3.4576 0.543 4.0226 2.184 0.244 0.577 0.343 1.197 0.435 1.812zm-5.1486 5.196c-2.5441 0.141-4.9995-1.595-5.6343-4.081-0.749-2.153-0.5283-4.63 0.8207-6.504 1.1151-1.702 2.6065-3.105 4.0286-4.543 0.183 1.127 0.366 2.254 0.549 3.382-2.9906 0.782-5.0046 4.725-3.215 7.451 0.5324 0.764 1.9765 2.223 2.7655 1.634-1.102-0.683-2.0033-1.859-1.8095-3.227-0.0821-1.282 1.3699-2.911 2.6513-3.198 0.4384 2.869 0.9413 6.073 1.3797 8.943-0.5054 0.1-1.0211 0.143-1.536 0.143z"/>
</svg>

        <g>{[100,200,300,400,500].map((y,idx) => <rect height={14} width={800}  fill={this.color()} x={0} y={y-7}/>
          )}</g>
          <g>{this.chordToClef().map((y,idx) => <g>
              
              {this.chordToFlats()[idx] && <g>
              <line x1={this.col(1)-20} y1={y-40} x2={this.col(1)-20} y2={y+40} strokeWidth={8}  stroke={this.color()} />
              <line x1={this.col(1) + 20} y1={y-40} x2={this.col(1) + 20} y2={y+40} strokeWidth={8}  stroke={this.color()} />
              <line x1={this.col(1) - 40} y1={y-20} x2={this.col(1) + 40} y2={y-20 - 10} stroke-width={16}  stroke={this.color()} />
              <line x1={this.col(1) - 40} y1={y+20} x2={this.col(1) + 40} y2={y+20 - 10 } stroke-width={16}  stroke={this.color()} />
              </g>}

              {1===2 && <svg width={58} height={175} x={this.col(3)} y={y} viewBox="0 0 58 175" >
                <path d="M0.5,0.5L7.479,0.5L7.479,101.934C13.255,95.998 18.408,91.607 22.941,88.759C27.473,85.911 31.183,84.487 34.071,84.487C37.44,84.487 40.508,85.109 43.276,86.352C46.043,87.596 48.47,89.36 50.555,91.647C52.641,93.933 54.245,96.6 55.368,99.648C56.492,102.697 57.053,106.026 57.053,109.635C57.053,111.962 56.371,114.468 55.007,117.156C53.644,119.843 51.999,122.53 50.074,125.217C48.149,127.905 46.103,130.432 43.938,132.798C41.772,135.164 39.846,137.19 38.162,138.874L1.342,173.528L0.5,0.5ZM7.84,162.218C10.728,158.447 13.816,154.376 17.105,150.005C20.394,145.633 23.402,141.08 26.129,136.348C28.857,131.615 31.123,126.722 32.928,121.668C34.733,116.614 35.635,111.561 35.635,106.507C35.635,103.218 34.753,100.571 32.988,98.565C31.223,96.56 28.857,95.557 25.889,95.557C23.402,95.557 20.815,96.219 18.128,97.543C15.44,98.866 13.255,100.45 11.57,102.295L7.479,107.469L7.84,162.218Z" fill={'rgb(255,255,255)'} style={{fill:'rgb(255,255,255)',fillRule:'nonzero',stroke:'rgb(110,37,169)',strokeWidth:1}}/>
              </svg>}

              <ellipse cx={!this.aboveNote(y,idx) ? this.col(2) : this.col(3)} cy={y} rx="62" ry="50" mask={'url(#masknote' + this.notes().join('') + '_' + y + ')'} fill={this.color()}/>
              <mask id={'masknote' + this.notes().join('')  + '_' + y}>
                <ellipse cx={!this.aboveNote(y,idx) ? this.col(2) : this.col(3)} cy={y} rx="62" ry="50" fill="white"/>
                <ellipse cx={!this.aboveNote(y,idx) ? this.col(2) : this.col(3)} cy={y} rx="24" ry="40" fill="black" transform={'rotate(45 ' + ((!this.aboveNote(y,idx) && this.col(2)) || this.col(3)) + ' ' + y + ')'}/>
              </mask>
              </g>
          )}</g>
        </svg></div>);
         
       
      }
    
  }
  export {FretShape, ChordStar, FretMap, MusicStaff, PianoMap, HornMap, trumpet, instrument,MIDIPlayer,fretEase};